















import Vue, { PropType } from 'vue'
import { PeerComment } from './peer-comment'

class CompetenceComment {
  constructor(
    public readonly competenceCode: string,
    public readonly competenceName: string,
    public readonly comment: string
  ) {}
}

export default Vue.extend({
  props: {
    modalId: {
      type: String,
      required: true,
    },
    peerComment: {
      type: Object as PropType<PeerComment>,
      required: true,
    },
  },

  computed: {
    competenceComments(): CompetenceComment[] {
      return this.peerComment.comments.map((rawComment) => {
        return new CompetenceComment(
          rawComment.competenceCode,
          rawComment.competenceName,
          rawComment.comment
        )
      })
    },
  },
})
