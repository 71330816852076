import axios from 'axios'
import { Evaluation, EvaluationDetail, EvaluationEditable } from './types'
import { AxiosUtils } from '@/utils/axios-utils'
import { CancellablePromise } from '@/utils/promise/cancellable-promise'
import FileSaver from 'file-saver'
import { ServerError } from '@/services/common/server-error'

enum EvaluationView {
  GENERAL = 'general',
  USER_COURSE = 'user',
}

export class EvaluationService {
  public getCourseEvaluations(courseId: number): CancellablePromise<Evaluation[]> {
    const cancelSource = axios.CancelToken.source()
    const promise = axios
      .get<Evaluation[]>('/api/evaluations', {
        cancelToken: cancelSource.token,
        params: {
          view: EvaluationView.GENERAL,
          courseId: courseId,
        },
      })
      .then((response) => response.data, AxiosUtils.returnValueIfCancelledOrRethrow([]))
    return new CancellablePromise(promise, cancelSource.cancel)
  }

  public getUserEvaluations(courseId: number, userId: number): CancellablePromise<Evaluation[]> {
    const cancelSource = axios.CancelToken.source()
    const promise = axios
      .get<Evaluation[]>(`/api/evaluations`, {
        cancelToken: cancelSource.token,
        params: {
          view: EvaluationView.USER_COURSE,
          courseId: courseId,
          userId: userId,
        },
      })
      .then((response) => response.data, AxiosUtils.returnValueIfCancelledOrRethrow([]))
    return new CancellablePromise(promise, cancelSource.cancel)
  }

  public getDetail(
    evaluationId: number,
    userId: number
  ): CancellablePromise<EvaluationDetail | null> {
    const cancelSource = axios.CancelToken.source()
    const promise = axios
      .get<EvaluationDetail>(`/api/evaluations/${evaluationId}`, {
        cancelToken: cancelSource.token,
        params: {
          userId: userId,
        },
      })
      .then((response) => response.data, AxiosUtils.returnValueIfCancelledOrRethrow(null))
    return new CancellablePromise(promise, cancelSource.cancel)
  }

  public async downloadAnswersDetail(evaluationId: number) {
    return await axios
      .get(`/api/evaluations/${evaluationId}/detail/download/xlsx`, {
        responseType: 'blob',
      })
      .then((response) => {
        const filename = AxiosUtils.getSuggestedFilename(response) ?? 'answer-details'
        FileSaver.saveAs(response.data, filename)
      })
  }

  public async update(evaluationId: number, data: Partial<EvaluationEditable>) {
    return await axios
      .patch<Evaluation>(`/api/evaluations/${evaluationId}`, {
        ...data,
      })
      .then(
        (response) => response.data,
        (error) => ServerError.from(error)
      )
  }

  public async setGradesPublished(evaluationId: number, published: boolean) {
    return await axios
      .post<Evaluation>('/api/grades/actions/set-published', {
        evaluationId: evaluationId,
        published: published,
      })
      .then(
        (response) => response.data,
        (error) => ServerError.from(error)
      )
  }
}
