


















































































import Vue from 'vue'
import { ChartData } from 'chart.js'
import { CompetenceDoubleRating } from './competence-double-rating'
import ProgressCircleDouble from '@/components/ProgressCircleDouble.vue'
import LineChart from '@/components/dashboard/chart/LineChart.vue'
import PlaceholderNoData from './PlaceholderNoData.vue'
import { GradesToChartDataConverter } from './chart/grades-to-chart-data-converter'
import { Grades } from '@/models/grades/grades'
import { Assert } from '@/utils/assert'
import { GradeStatsService } from '@/services/grade-stats/grade-stats-service'

const chartDataConverter = new GradesToChartDataConverter()

export default Vue.extend({
  components: {
    ProgressCircleDouble,
    LineChart,
    PlaceholderNoData,
  },

  props: {
    courseId: {
      type: Number,
      required: true,
    },
    userId: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      autoEvaluationRatings: [] as CompetenceDoubleRating[],
      questionnaireRatings: [] as CompetenceDoubleRating[],
      isLoading: true,
      progressionData: undefined as ChartData | undefined,
    }
  },

  created() {
    this.isLoading = true
    const gradesPromise = new GradeStatsService().getAllStats(this.courseId, this.userId).promise
    gradesPromise
      .then((maybeGrades) => {
        const grades = Assert.notNullOrUndefined(maybeGrades)
        this.updateRatings(grades)
        this.updateChartData(grades)
      })
      .finally(() => {
        this.isLoading = false
      })
  },

  methods: {
    updateChartData(grades: Grades) {
      this.progressionData = chartDataConverter.convertToUserProgression(grades)
    },
    updateRatings(grades: Grades) {
      const competences = grades.getCompetences()
      const autoEvaluationRatings = [] as CompetenceDoubleRating[]
      const questionnaireRatings = [] as CompetenceDoubleRating[]
      for (const competence of competences) {
        const courseStat = grades.courseStats.find((x) => x.competenceId === competence.id)
        const courseUserStat = grades.courseUserStats.find((x) => x.competenceId === competence.id)
        const questionnaireRating = new CompetenceDoubleRating(
          competence.name,
          courseUserStat?.questionnaireAvg ?? null,
          courseStat?.questionnaireAvg ?? null
        )
        const autoEvaluationRating = new CompetenceDoubleRating(
          competence.name,
          courseUserStat?.autoEvaluationAvg ?? null,
          courseUserStat?.peersPerceptionAvg ?? null
        )
        autoEvaluationRatings.push(autoEvaluationRating)
        questionnaireRatings.push(questionnaireRating)
      }
      this.autoEvaluationRatings = autoEvaluationRatings
      this.questionnaireRatings = questionnaireRatings
    },
  },
})
