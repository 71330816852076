import axios from 'axios'
import { UserDeserializer } from '@/services/user/user-deserializer'
import { AxiosUtils } from '@/utils/axios-utils'
import { CancellablePromise } from '@/utils/promise/cancellable-promise'
import { User } from './user'
import { UserRole } from './user-roles'
import { ServerError } from '../common/server-error'

const userDeserializer = new UserDeserializer()

export type CreateResult = {
  user?: User
  errors?: any
}

export class UserService {
  public async getCurrentUser() {
    return axios.get('/api/me').then((response) => {
      return userDeserializer.deserialize(response.data)
    })
  }

  public async getUser(userId: number) {
    return axios.get(`/api/users/${userId}`).then((response) => {
      return userDeserializer.deserialize(response.data)
    })
  }

  public getUsers(courseId: number | undefined) {
    const cancelSource = axios.CancelToken.source()
    const promise = axios
      .get('/api/users', {
        cancelToken: cancelSource.token,
        params: {
          courseId: courseId,
        },
      })
      .then((response) => {
        const users = response.data as any[]
        return users.map((x) => userDeserializer.deserialize(x))
      }, AxiosUtils.returnValueIfCancelledOrRethrow(new Array<User>()))
    return new CancellablePromise(promise, cancelSource.cancel)
  }

  public async create(name: string, email: string, role: UserRole) {
    return axios
      .post('/api/users', {
        name: name,
        email: email,
        role: role,
      })
      .then(
        (response) => userDeserializer.deserialize(response.data),
        (error) => ServerError.from(error)
      )
  }

  public async sendWelcomeNotification(userId: number) {
    return axios.post(`/api/users/${userId}/send-welcome-notification`).then(() => {})
  }
}
