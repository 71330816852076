



















import Vue from 'vue'
import { RouteName } from '@/router/route-name'

export default Vue.extend({
  computed: {
    tabs() {
      return [
        {
          icon: 'house',
          name: this.$t('go_home'),
          route: RouteName.HOME,
        },
        {
          icon: 'person',
          name: this.$t('profile'),
          route: 'settings.profile',
        },
        {
          icon: 'lock',
          name: this.$t('password'),
          route: 'settings.password',
        },
      ]
    },
  },
})
