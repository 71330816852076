










































































import Vue, { PropType } from 'vue'
import { MetaInfo } from 'vue-meta'
import { BreadcrumbItem } from '@/components/breadcrumb/breadcrumb-item'
import { RouteName } from '@/router/route-name'
import { EvaluationService } from '@/services/evaluation/evaluation-service'
import { Logger } from '@/modules/logger'
import {
  EvaluationEditable,
  EvaluationDetail,
  EvaluationStatusCode,
} from '@/services/evaluation/types'
import { ServerError } from '@/services/common/server-error'
import { ValidationObserverInstance } from '@/utils/misc-types'
import { AuthStoreKey } from '@/store/modules/auth-store'
import { User } from '@/services/user/user'
import { EvaluationStatus } from '@/models/evaluation-status'
import { Assert } from '@/utils/assert'

const evaluationService = new EvaluationService()

export default Vue.extend({
  components: {},

  props: {
    evaluationId: {
      type: Number as PropType<number>,
      required: true,
    },
  },

  data() {
    return {
      name: '',
      isPublished: false,
      gradesAreReady: false,
      statusCode: null as EvaluationStatusCode | null,
      breadcrumbItems: getBreadcrumbItems(this.$t.bind(this)),
      previewVisible: true,
      isSettingUp: true,
      isSaving: false,
      serverErrors: new Array<string>(),
      debouncedUpdateDescriptionHtml: () => {},
    }
  },

  computed: {
    statusLabelKey(): string {
      return this.statusCode ? EvaluationStatus.codeToTranslationKey(this.statusCode) : null
    },
    evaluation: {
      get(): EvaluationEditable {
        return {
          name: this.name,
          is_published: this.isPublished ? 1 : 0,
        }
      },
      set(evaluation: EvaluationEditable) {
        this.name = evaluation.name
        this.isPublished = Boolean(evaluation.is_published)
      },
    },
    user(): User {
      return this.$store.getters[AuthStoreKey.GETTER_USER]
    },
    veeObserver(): ValidationObserverInstance {
      return this.$refs.veeObserver as ValidationObserverInstance
    },
    toggleGradesReadyBtnLabelKey(): string {
      return this.gradesAreReady ? 'unpublish_grades' : 'publish_grades'
    },
    canSetGradesPublishedState(): boolean {
      return this.statusCode === EvaluationStatusCode.DONE
    },
  },

  metaInfo(): MetaInfo {
    return {
      title: this.$tc('competence_detail_page_title'),
    }
  },

  created() {
    this.fetchEvaluation()
  },

  methods: {
    async fetchEvaluation() {
      this.isSettingUp = true

      try {
        const evaluationDetail: EvaluationDetail | null = await evaluationService.getDetail(
          this.evaluationId,
          this.user.id
        ).promise

        const fetchedEvaluation = evaluationDetail!.evaluation
        this.statusCode = fetchedEvaluation.evaluation_status.code
        this.gradesAreReady = Boolean(fetchedEvaluation.grades_are_ready)
        this.evaluation = {
          name: fetchedEvaluation.name,
          is_published: fetchedEvaluation.is_published,
        }
      } catch (error) {
        Logger.error(error)
      }
      this.isSettingUp = false
    },

    async trySave() {
      const formIsValid = await this.isFormValid()
      if (formIsValid) {
        await this.doSubmit()
      }
    },

    async tryCancel() {
      if (this.isFormDirty()) {
        const confirms = await this.$bvModal.msgBoxConfirm(this.$tc('general_unsaved_confirmation'))
        if (!confirms) {
          return
        }
      }
      this.leave()
    },

    async doSubmit() {
      this.isSaving = true
      this.serverErrors = []
      try {
        const evaluationOrError = await evaluationService.update(this.evaluationId, this.evaluation)
        if (evaluationOrError instanceof ServerError) {
          const error = evaluationOrError
          this.serverErrors = error.errorsAsSimpleFormat()
        } else {
          this.evaluation = evaluationOrError
          this.leave()
        }
      } finally {
        this.isSaving = false
      }
    },

    async isFormValid() {
      return this.veeObserver.validate()
    },

    isFormDirty() {
      return this.veeObserver.flags.dirty
    },

    leave() {
      this.$router.push({ name: RouteName.ADMIN_EVALUATION_LIST })
    },

    async downloadResults() {
      this.isSaving = true
      try {
        await new EvaluationService().downloadAnswersDetail(this.evaluationId)
      } finally {
        this.isSaving = false
      }
    },

    async setGradesReady(newState: boolean) {
      this.isSaving = true
      this.serverErrors = []
      const confirms = await this.$bvModal.msgBoxConfirm(this.$tc('set_publish_grades_warning'))
      if (confirms) {
        try {
          const latestEvaluationStateOrError = await new EvaluationService().setGradesPublished(
            this.evaluationId,
            newState
          )
          if (latestEvaluationStateOrError instanceof ServerError) {
            const error = Assert.isInstanceOf(latestEvaluationStateOrError, ServerError)
            this.serverErrors = error.errorsAsSimpleFormat()
          } else {
            this.gradesAreReady = latestEvaluationStateOrError.grades_are_ready
          }
        } catch (error) {
          Logger.error(error)
        }
      }
      this.isSaving = false
    },
  },
})

function getBreadcrumbItems($t: Function): BreadcrumbItem[] {
  return [
    new BreadcrumbItem(
      $t('evaluations_page_title'),
      { name: RouteName.ADMIN_EVALUATION_LIST },
      false
    ),
    new BreadcrumbItem($t('evaluation_detail_page_title'), null, true),
  ]
}
