import { Grades } from '@/models/grades/grades'
import { EvaluationStat } from '@/models/grades/evaluation-stat'
import { CompetenceDeserializer } from '@/services/grade-stats/competence-deserializer'
import { EvaluationDeserializer } from '@/services/grade-stats/evaluation-deserializer'
import { Competence } from '@/models/grades/competence'
import { Evaluation } from '@/models/grades/evaluation'
import { CourseStat } from '@/models/grades/course-stat'
import { CourseUserStat } from '@/models/grades/course-user-stat'
import { EvaluationUserStat } from '@/models/grades/evaluation-user-stat'

const competenceDeserializer = new CompetenceDeserializer()
const evaluationDeserializer = new EvaluationDeserializer()

export class GradesDeserializer {
  deserialize(json: any): Grades {
    const courseStats = this.deserializeCourseStats(json.courseStats as any[])
    const courseUserStats = this.deserializeCourseUserStats(json.courseUserStats as any[])
    const evaluationUserStats = this.deserializeEvaluationUserStats(
      json.evaluationUserStats as any[]
    )
    const evaluationStats = this.deserializeEvaluationStats(json.evaluationStats as any[])
    const competencesById = competenceDeserializer
      .deserializeMany(json.competences as any[])
      .reduce((map: Map<number, Competence>, competence: Competence) => {
        map.set(competence.id, competence)
        return map
      }, new Map())
    const evaluationsById = evaluationDeserializer
      .deserializeMany(json.evaluations as any[])
      .reduce((map: Map<number, Evaluation>, evaluation: Evaluation) => {
        map.set(evaluation.id, evaluation)
        return map
      }, new Map())
    return new Grades(
      courseStats,
      courseUserStats,
      evaluationUserStats,
      evaluationStats,
      competencesById,
      evaluationsById
    )
  }

  private deserializeCourseStats(json: any[]): CourseStat[] {
    return json.map((x) => this.deserializeCourseStat(x))
  }

  private deserializeCourseStat(json: any): CourseStat {
    return new CourseStat(
      json.competence_id as number,
      json.questionnaire_avg as string,
      json.auto_evaluation_avg as string
    )
  }

  private deserializeCourseUserStats(json: any[]): CourseUserStat[] {
    return json.map((x) => this.deserializeCourseUserStat(x))
  }

  private deserializeCourseUserStat(json: any): CourseUserStat {
    return new CourseUserStat(
      json.course_id as number,
      json.competence_id as number,
      json.user_id as number,
      json.auto_evaluation_avg as string,
      json.questionnaire_avg as string,
      json.peers_perception_avg as string
    )
  }

  private deserializeEvaluationUserStats(json: any[]): EvaluationUserStat[] {
    return json.map((x) => this.deserializeEvaluationUserStat(x))
  }

  private deserializeEvaluationUserStat(json: any): EvaluationUserStat {
    return new EvaluationUserStat(
      json.evaluation_id as number,
      json.competence_id as number,
      json.user_id as number,
      json.auto_evaluation_avg as string,
      json.questionnaire_avg as string,
      json.peers_perception_avg as string
    )
  }

  private deserializeEvaluationStats(json: any[]): EvaluationStat[] {
    return json.map((x) => this.deserializeEvaluationStat(x))
  }

  private deserializeEvaluationStat(json: any): EvaluationStat {
    return new EvaluationStat(
      json.competence_id as number,
      json.evaluation_id as number,
      json.questionnaire_avg as string
    )
  }
}
