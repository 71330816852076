






















































































































































import Vue, { PropType } from 'vue'
import {
  QuestionnaireSection,
  EvaluationAnswer,
  QuestionnaireQuestion,
} from './questionnaire-section'
import QuestionnaireRadioGroup from '@/components/QuestionnaireRadioGroup.vue'
import ldDebounce from 'lodash/debounce'
import { User } from '@/services/user/user'
import { QuestionPreamble, AvailableChoicesSet, Competence } from '@/services/questionnaire/types'
import { ValidationObserverInstance } from '@/utils/misc-types'
import { AuthStoreKey } from '@/store/modules/auth-store'
import { QuestionnaireService } from '@/services/questionnaire/questionnaire-service'

const SHOW_INDICATOR_THRESHOLD = 0.8 // Shows indicator at 80% full
const SAVE_DEBOUNCE_TIMEOUT = 5000
const SAVE_DEBOUNCE_MAX_TIMEOUT = 30000

function normalizedLdDebounceFlush<T>(flushFn: () => any | undefined, defaultValue: T): T {
  const rawResult = flushFn()
  return rawResult === undefined ? defaultValue : rawResult
}

export default Vue.extend({
  components: {
    QuestionnaireRadioGroup,
  },

  props: {
    availableChoicesSets: {
      type: Array as PropType<AvailableChoicesSet[]>,
      required: true,
    },
    questionPreambles: {
      type: Array as PropType<QuestionPreamble[]>,
      required: true,
    },
    questions: {
      type: Array as PropType<QuestionnaireQuestion[]>,
      required: true,
    },
    competence: {
      type: Object as PropType<Competence>,
      required: true,
    },
    onExit: {
      type: Function as PropType<() => {}>,
      required: true,
    },
    setOnLeaveQuestionnaireHook: {
      type: Function as PropType<(hook: () => Promise<boolean>) => void>,
      required: true,
    },
  },

  data() {
    return {
      allQuestions: new Array<QuestionnaireQuestion>(),
      sections: new Array<QuestionnaireSection>(),
      currentQuestion: undefined as QuestionnaireQuestion | undefined,
      currentQuestionIndex: -1,
      showLoadingIndicator: false,
      publicPath: process.env.BASE_URL as string,
    }
  },

  computed: {
    user(): User {
      return this.$store.getters[AuthStoreKey.GETTER_USER]
    },
    answerInputTypeCode(): string | undefined {
      return this.currentQuestion?.answerInputTypeCode
    },
    currentAnswer(): EvaluationAnswer | undefined {
      return this.currentQuestion?.answer
    },
    currentAnswerValueFormattedAsInteger(): string {
      const valueDecimal = this.currentAnswer!.data.value_decimal!
      const valueAsInteger = valueDecimal >= 0 ? Math.round(valueDecimal) : valueDecimal
      return valueAsInteger.toString()
    },
    count(): number {
      return this.currentAnswer?.data?.value_text?.length ?? 0
    },
    maxLength(): number {
      return EvaluationAnswer.TEXT_MAX_LENGTH
    },
    showCharLimitIndicator(): boolean {
      if (this.maxLength === 0) {
        return false
      }
      return this.count / this.maxLength >= SHOW_INDICATOR_THRESHOLD
    },
  },

  beforeMount() {
    this.setOnLeaveQuestionnaireHook(this.flushSaveBuffer)
  },

  beforeDestroy() {
    this.setOnLeaveQuestionnaireHook(() => Promise.resolve(true))
  },

  mounted() {
    const currentCompetenceId = this.competence.id
    const evaluationQuestions = this.questions.filter((x) => {
      return x.data.competence_id === currentCompetenceId
    })
    const availableSectionCodes = [
      QuestionnaireSection.CODES.AUTO_EVAL,
      QuestionnaireSection.CODES.QUESTIONNAIRE,
      QuestionnaireSection.CODES.PEER_EVAL,
    ]
    for (const sectionCode of availableSectionCodes) {
      const sectionQuestions = this.filterEvaluationQuestionsBySectionCode(
        evaluationQuestions,
        sectionCode
      )
      if (sectionQuestions.length > 0) {
        const sectionId = this.sections.length
        const sectionIndex = sectionId
        const sectionTitleTransKey = QuestionnaireSection.codeToTransKey(sectionCode)
        const sectionTitle = this.$tc(sectionTitleTransKey)
        const section = new QuestionnaireSection(sectionId, sectionCode, sectionIndex, sectionTitle)
        section.addQuestions(sectionQuestions)
        this.allQuestions.push(...sectionQuestions)
        this.sections.push(section)
      }
    }

    this.setCurrentQuestion(this.allQuestions[0], 0)
  },

  methods: {
    setSelectedQuestion(question: QuestionnaireQuestion) {
      this.setCurrentQuestion(question, this.allQuestions.indexOf(question))
    },

    selectQuestionByIndex(index: number) {
      this.setCurrentQuestion(this.allQuestions[index], index)
    },

    validate(): Promise<boolean> {
      return (this.$refs.veeObserver as ValidationObserverInstance).validate()
    },

    flushSaveBuffer(): Promise<boolean> {
      this.showSavingIndicators()

      const validatePromise = this.validate()
      const validateAndSavePromise = validatePromise.then((isValid: boolean) => {
        if (!isValid) {
          return Promise.resolve(false)
        }
        const noOp = () => {}
        const flushFn = this.currentAnswer?.debouncedSaveAnswer?.flush ?? noOp
        return normalizedLdDebounceFlush(flushFn, Promise.resolve(true))
      })

      validateAndSavePromise.finally(() => this.hideSavingIndicators())
      return validateAndSavePromise
    },

    setCurrentQuestion(question: QuestionnaireQuestion, index: number) {
      this.flushSaveBuffer().then((isSuccess) => {
        if (!isSuccess) {
          return
        }

        const toAnswer = question.answer
        const saveFn = this.createSaveFn(toAnswer)
        toAnswer.debouncedSaveAnswer = ldDebounce(saveFn, SAVE_DEBOUNCE_TIMEOUT, {
          maxWait: SAVE_DEBOUNCE_MAX_TIMEOUT,
        })
        this.doSetCurrentQuestion(question, index)
      })
    },

    createSaveFn(answer: EvaluationAnswer) {
      return () => {
        if ((this.$refs.veeObserver as ValidationObserverInstance).flags.pristine) {
          return Promise.resolve(true)
        }

        return this.validate().then((isValid) => {
          if (isValid) {
            return this.save(answer)
          }
          return Promise.resolve(false)
        })
      }
    },

    doSetCurrentQuestion(question: QuestionnaireQuestion, index: number) {
      requestAnimationFrame(() => {
        ;(this.$refs.veeObserver as ValidationObserverInstance).reset()
      })
      this.currentQuestion = question
      this.currentQuestionIndex = index
    },

    showErrorMessage(message: string) {
      this.$bvModal.msgBoxOk(message, {
        title: this.$tc('general_error'),
        headerTextVariant: 'danger',
      })
    },

    selectNextQuestion() {
      this.selectQuestionByIndex(this.currentQuestionIndex + 1)
    },

    selectPreviousQuestion() {
      this.selectQuestionByIndex(this.currentQuestionIndex - 1)
    },

    async finish() {
      const isSuccess = await this.flushSaveBuffer()
      if (isSuccess) {
        this.onExit()
      }
    },

    save(answer: EvaluationAnswer) {
      return new QuestionnaireService()
        .saveAnswer(answer.data)
        .then((response) => {
          answer.data.id = response.id
          answer.isDirty = false
          return true
        })
        .catch((axiosError) => {
          const message = axiosError?.response?.data?.message ?? this.$tc('general_error_unknown')
          this.showErrorMessage(message)
          return false
        })
    },

    filterEvaluationQuestionsBySectionCode(
      evaluationQuestions: QuestionnaireQuestion[],
      code: string
    ) {
      return evaluationQuestions.filter((q) => {
        const qCode = QuestionnaireSection.questionUsersToCode(
          this.user.id,
          q.data.respondent_id,
          q.data.evaluatee_id
        )
        return code === qCode
      })
    },

    showSavingIndicators(): void {
      this.showLoadingIndicator = true
    },

    hideSavingIndicators(): void {
      this.showLoadingIndicator = false
    },

    onAnswerChange() {
      this.currentAnswer!.isDirty = true // TODO: recheck necessity for isDirty
      this.currentAnswer!.debouncedSaveAnswer!()
    },
  },
})
