import { render, staticRenderFns } from "./QuestionnaireRadioGroupItem.vue?vue&type=template&id=34f96e07&scoped=true&"
import script from "./QuestionnaireRadioGroupItem.vue?vue&type=script&lang=ts&"
export * from "./QuestionnaireRadioGroupItem.vue?vue&type=script&lang=ts&"
import style0 from "./QuestionnaireRadioGroupItem.vue?vue&type=style&index=0&id=34f96e07&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "34f96e07",
  null
  
)

export default component.exports