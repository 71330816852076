








































import Vue, { PropType } from 'vue'
import { Editor, EditorContent, EditorMenuBar } from 'tiptap'
import {
  Heading,
  OrderedList,
  BulletList,
  ListItem,
  Bold,
  Italic,
  Link,
  Underline,
  History,
} from 'tiptap-extensions'

export default Vue.extend({
  components: {
    EditorContent,
    EditorMenuBar,
  },

  props: {
    initialContent: {
      type: String,
      required: true,
    },
    onUpdate: {
      type: Function as PropType<() => void>,
      required: false,
      default: function () {
        return () => {}
      },
    },
    onBlur: {
      type: Function as PropType<() => void>,
      required: false,
      default: function () {
        return () => {}
      },
    },
  },

  data() {
    return {
      editor: new Editor({
        extensions: [
          new BulletList(),
          new Heading({ levels: [3, 4, 5] }),
          new ListItem(),
          new OrderedList(),
          new Link(),
          new Bold(),
          new Italic(),
          new Underline(),
          new History(),
        ],
        content: this.initialContent,
        onUpdate: () => this.onUpdate(),
        onBlur: () => this.onBlur(),
      }),
    }
  },

  beforeDestroy() {
    this.editor.destroy()
  },

  methods: {
    getHtml(): string {
      return this.editor.getHTML()
    },
  },
})
