
































































import Vue from 'vue'
import SelectCourse from '@/components/SelectCourse.vue'
import { AuthStoreKey } from '@/store/modules/auth-store'
import { User } from '@/services/user/user'
import { RouteName } from '@/router/route-name'

export default Vue.extend({
  components: {
    SelectCourse,
  },

  data() {
    return {
      publicPath: process.env.BASE_URL as string,
      techSupportEmail: null as null | string,
    }
  },

  computed: {
    user(): User {
      return this.$store.getters[AuthStoreKey.GETTER_USER]
    },
    techSupportEmailHref(): string | null {
      return this.techSupportEmail ? `mailto:${this.techSupportEmail}` : null
    },

    isAdmin(): boolean {
      return User.isAdmin(this.$store.getters[AuthStoreKey.GETTER_USER])
    },

    adminItems(): any[] {
      const items = [
        {
          icon: 'star',
          name: this.$t('competence_list_page_title'),
          route: RouteName.COMPETENCE_LIST,
        },
        {
          icon: 'person-check',
          name: this.$t('inscription_list_page_title'),
          route: RouteName.INSCRIPTION_LIST,
        },
        {
          icon: 'people',
          name: this.$t('user_list_page_title'),
          route: RouteName.USER_LIST,
        },
        {
          icon: 'bar-chart',
          name: this.$t('evaluations_admin_page_title'),
          route: RouteName.ADMIN_EVALUATION_LIST,
        },
        {
          icon: 'gear',
          name: this.$t('admin_various_page_title'),
          route: RouteName.ADMIN_VARIOUS,
        },
      ]
      return this.isAdmin ? items : []
    },
  },

  methods: {
    async logout() {
      await this.$store.dispatch(AuthStoreKey.ACTION_LOGOUT)
      this.$router.push({ name: 'login' })
    },
  },
})
