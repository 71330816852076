export class BreadcrumbItem {
  public readonly text: string
  public readonly to: string | {} | null
  public readonly active: boolean

  constructor(text: string, to: string | {} | null, active: boolean) {
    this.text = text
    this.to = to
    this.active = active
  }
}
