const JSON_KEY_ACCESS_TOKEN = 'access-token'
const JSON_KEY_ACCESS_EXPIRES_AT = 'expires-at'

export class JwtToken {
  public readonly accessToken: string
  public readonly expiresAt: Date

  constructor(accessToken: string, expiresAt: Date) {
    this.accessToken = accessToken
    this.expiresAt = expiresAt
  }

  serialize(): string {
    return JSON.stringify({
      [JSON_KEY_ACCESS_TOKEN]: this.accessToken,
      [JSON_KEY_ACCESS_EXPIRES_AT]: this.expiresAt,
    })
  }

  static deserialize(strignified: string) {
    const json = JSON.parse(strignified)
    return new JwtToken(json[JSON_KEY_ACCESS_TOKEN], new Date(json[JSON_KEY_ACCESS_EXPIRES_AT]))
  }
}
