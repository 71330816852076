import { Course } from '@/models/course'

export class CourseDeserializer {
  deserializeMany(json: any[]): Course[] {
    return json.map((x) => this.deserialize(x))
  }

  deserialize(json: any): Course {
    return new Course(json.id as number, json.name as string, json.start_datetime as string)
  }
}
