












import Vue from 'vue'
import { MetaInfo } from 'vue-meta'
import VLoadingTopBar from './VLoadingTopBar.vue'
import { layouts, Layout } from '@/layouts/index'
import { Assert } from '@/utils/assert'
import { LoadingStoreKey } from '@/store/modules/loading-store'
import { LayoutName } from '@/layouts/layout-name'

export default Vue.extend({
  components: {
    VLoadingTopBar,
  },

  data() {
    return {}
  },

  computed: {
    layoutName(): LayoutName {
      return this.$store.state.layout.layoutName
    },
    layout(): Layout {
      return Assert.isDefined(layouts.get(this.layoutName))
    },
    showLoadingOverlay() {
      return this.$store.getters[LoadingStoreKey.GETTER_ROUTER_LOADING]
    },
    showTopLoadingBar() {
      return this.$store.getters[LoadingStoreKey.GETTER_REQUEST_PENDING]
    },
  },

  metaInfo(): MetaInfo {
    const appName = this.$tc('app_name')

    return {
      title: undefined,
      titleTemplate: (titleChunk) => {
        return titleChunk ? `${titleChunk} · ${appName}` : appName
      },
    }
  },
})
