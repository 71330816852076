


































































import Vue from 'vue'
import { Logger } from '@/modules/logger'
import { EvaluationService } from '@/services/evaluation/evaluation-service'
import moment from 'moment'
import { EvaluationStatus } from '@/models/evaluation-status'
import { MetaInfo } from 'vue-meta'
import { Evaluation, EvaluationStatusCode } from '@/services/evaluation/types'
import ReportCard from '@/components/report-card/ReportCard.vue'
import BannerNotEnrolled from '@/components/banners/BannerNotEnrolled.vue'
import { AuthStoreKey } from '@/store/modules/auth-store'
import { RouteName } from '@/router/route-name'
import { User } from '@/services/user/user'

const REPORT_CARD_MODAL_ID = 'reportCardModal'

export default Vue.extend({
  components: {
    BannerNotEnrolled,
    ReportCard,
  },

  data() {
    return {
      fields: [
        {
          key: 'name',
          label: this.$t('evaluation_label'),
        },
        {
          key: 'startDatetime',
          label: this.$t('general_begin'),
        },
        {
          key: 'endDatetime',
          label: this.$t('general_finish'),
        },
        {
          key: 'completion',
          label: this.$t('evaluation_list_header_completed'),
          class: 'text-center',
        },
        {
          key: 'evalutationStatus',
          label: this.$t('status'),
          class: 'text-center',
        },
      ],
      isBusy: false,
      cancelFetchData: () => {},
      reportCardModalId: REPORT_CARD_MODAL_ID,
    }
  },

  computed: {
    currentCourseId(): number | null {
      return this.$store.getters[AuthStoreKey.GETTER_CURRENT_COURSE]?.id ?? null
    },
    user(): User {
      return this.$store.getters[AuthStoreKey.GETTER_USER]
    },
  },

  metaInfo(): MetaInfo {
    return {
      title: this.$tc('evaluations_page_title'),
    }
  },

  destroyed() {
    this.cancelFetchData()
  },

  methods: {
    fetchData() {
      const cancellablePromise = new EvaluationService().getUserEvaluations(
        this.currentCourseId!,
        this.user.id
      )
      this.cancelFetchData = cancellablePromise.cancel
      return cancellablePromise.promise
        .then((rawEvaluations) => {
          const evaluations = rawEvaluations.map((x) => {
            return this.responseEvaluationToRowData(x)
          })
          return evaluations
        })
        .catch((error) => {
          Logger.error(error)
          return []
        })
    },

    responseEvaluationToRowData(ev: Evaluation) {
      const statusCode = ev.status_code
      const isOpen = statusCode === EvaluationStatusCode.OPEN_FOR_SUBMISSIONS
      const endDatetime = ev.end_datetime ? moment(ev.end_datetime).format() : ''
      const startDatetime = ev.start_datetime ? moment(ev.start_datetime).format() : ''
      const questionsCount = ev.question_count
      const answersCount = ev.answered_count
      const unansweredCount = questionsCount - answersCount
      const completionIndicatorLabel = this.$t('evaluation_list_completion_indicator_label', {
        unanswered: unansweredCount,
      })
      const isCompleted = questionsCount - answersCount === 0
      const evalutationStatus = this.$t(EvaluationStatus.codeToTranslationKey(statusCode))
      return {
        id: ev.id,
        completionIndicatorLabel: completionIndicatorLabel,
        isCompleted: isCompleted,
        isOpen: isOpen,
        name: ev.name,
        startDatetime: startDatetime,
        endDatetime: endDatetime,
        evalutationStatus: evalutationStatus,
        routerToParams: getItemRouteParams(ev),
      }
    },

    showReportCardModal() {
      this.$bvModal.show(this.reportCardModalId)
    },
  },
})

function getItemRouteParams(ev: Evaluation) {
  if (ev.status_code === EvaluationStatusCode.OPEN_FOR_SUBMISSIONS) {
    return {
      name: 'questionnaire',
      params: { evaluationId: ev.id.toString() },
    }
  } else if (ev.grades_are_ready) {
    return {
      name: RouteName.EVALUATION_DETAIL,
      params: { evaluationId: ev.id.toString() },
    }
  }
  return null
}
