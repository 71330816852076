const defaultAlpha = 0.8

export class ChartColor {
  public rgbaString: string

  constructor(r: number, g: number, b: number, a: number) {
    this.rgbaString = rgbaToString(r, g, b, a)
  }
}

export const defaultChartColors = [
  new ChartColor(131, 195, 23, defaultAlpha),
  new ChartColor(0, 118, 214, defaultAlpha),
  new ChartColor(255, 67, 100, defaultAlpha),
  new ChartColor(255, 186, 74, defaultAlpha),
]

function rgbaToString(r: number, g: number, b: number, a: number) {
  return `rgba(${r}, ${g}, ${b}, ${a})`
}
