




































import Vue from 'vue'
import Form from 'vform'
import { MetaInfo } from 'vue-meta'

export default Vue.extend({
  metaInfo(): MetaInfo {
    return { title: this.$tc('reset_password') }
  },

  props: {
    initialEmail: {
      type: String,
      required: false,
      default: '',
    },
  },

  data() {
    return {
      status: '',
      form: new Form({
        email: this.initialEmail,
      }),
    }
  },

  methods: {
    async send() {
      const { data } = await this.form.post('/api/password/email')

      this.status = data.status

      this.form.reset()
    },
  },
})
