import ldIsNil from 'lodash/isNil'
import i18n from '@/plugins/i18n'
import { BigDecimal, NumberUtils } from './number-utils'

const MAX_LETTERS_IN_ACTONYM = 3

export class LabelUtils {
  static toOptionalLabel(x: any) {
    return ldIsNil(x) ? i18n.t('general_unavailable_short') : x
  }

  static bigDecimalToText(value: BigDecimal | null | undefined, precision = 0) {
    const optionalRounded = ldIsNil(value)
      ? undefined
      : NumberUtils.round(NumberUtils.bigDecimalToNumber(value), precision)
    return LabelUtils.toOptionalLabel(optionalRounded)
  }

  static toNameAcronym(value: string | null | undefined): string | null {
    return value
      ? value
          .split(' ')
          .filter((word) => word.length > 0)
          .map((word) => word[0])
          .join('')
          .substr(0, MAX_LETTERS_IN_ACTONYM)
      : null
  }
}
