













































import Vue from 'vue'

export default Vue.extend({
  props: {
    competence: {
      type: Object,
      required: true,
    },
    onSubmit: {
      type: Function,
      required: true,
    },
    onCancel: {
      type: Function,
      required: true,
    },
  },
})
