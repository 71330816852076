





































import Vue from 'vue'
import { MetaInfo } from 'vue-meta'
import { Logger } from '@/modules/logger'
import { RouteName } from '@/router/route-name'
import { CompetenceService } from '@/services/competence/competence-service'

export default Vue.extend({
  data() {
    return {
      pageTitle: this.$tc('competence_list_page_title'),
      isBusy: false,
      fields: [
        {
          key: 'name',
          label: this.$t('name'),
          class: 'align-middle',
        },
      ],
      totalRows: 0,
      currentPage: 1,
      perPage: 0,
    }
  },

  metaInfo(): MetaInfo {
    return {
      title: this.pageTitle,
    }
  },

  methods: {
    async competencesProvider() {
      this.isBusy = true
      try {
        const pagedCompetences = await new CompetenceService().getCompetences(this.currentPage)
        this.totalRows = pagedCompetences.total
        this.currentPage = pagedCompetences.current_page
        this.perPage = pagedCompetences.per_page
        return pagedCompetences.data
      } catch (error) {
        Logger.error(error)
      } finally {
        this.isBusy = false
      }
      return []
    },

    getDetailPageRoute(competenceId: number): {} {
      return {
        name: RouteName.COMPETENCE_DETAIL,
        params: { competenceIdOrNew: competenceId.toString() },
      }
    },

    create() {
      this.$router.push({ name: RouteName.COMPETENCE_DETAIL, params: { competenceIdOrNew: 'new' } })
    },
  },
})
