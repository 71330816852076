
















































import Vue from 'vue'
import { MetaInfo } from 'vue-meta'
import { Logger } from '@/modules/logger'
import UserCreateModal from '@/components/UserCreateModal.vue'
import { UserService } from '@/services/user/user-service'
import { UserRoleUtil, UserRole } from '@/services/user/user-roles'
import { User } from '@/services/user/user'

const userService = new UserService()

export default Vue.extend({
  components: {
    UserCreateModal,
  },

  data() {
    const self = this
    return {
      users: new Array<User>(),
      fields: [
        {
          key: 'name',
          label: self.$t('name'),
          class: 'align-middle',
          sortable: true,
        },
        {
          key: 'role',
          label: self.$t('general_user_role'),
          class: 'align-middle',
          sortable: true,
        },
        {
          key: 'actions',
          label: self.$t('general_actions'),
          class: 'align-middle',
        },
      ],
      isLoadingData: false,
      cancelFetchData: () => {},
      createUserModalId: 'createUserModalId',
    }
  },

  metaInfo(): MetaInfo {
    return {
      title: this.$tc('user_list_page_title'),
    }
  },

  created() {
    this.fetchData()
  },

  destroyed() {
    this.cancelFetchData()
  },

  methods: {
    fetchData() {
      this.isLoadingData = true
      const cancellablePromise = userService.getUsers(undefined)
      this.cancelFetchData = cancellablePromise.cancel
      return cancellablePromise.promise
        .catch((error) => {
          Logger.error(error)
          return []
        })
        .then((users) => {
          this.users = users
          this.isLoadingData = false
        })
    },

    roleCodeToLabel(code: UserRole) {
      return this.$tc(UserRoleUtil.codeToTranslationKey(code))
    },

    showCreateUserModal() {
      this.$bvModal.show(this.createUserModalId)
    },

    onUserCreated(user: User) {
      this.users.push(user)
    },

    async confirmSendUserNotification(userId: number) {
      const confirms = await this.$bvModal.msgBoxConfirm(this.$tc('general_confirm_message'))
      if (confirms) {
        userService.sendWelcomeNotification(userId)
      }
    },
  },
})
