import { Evaluation } from '@/models/grades/evaluation'

export class EvaluationDeserializer {
  deserializeMany(json: any[]): Evaluation[] {
    return json.map((x) => this.deserialize(x))
  }

  deserialize(json: any): Evaluation {
    return new Evaluation(json.id as number, json.name as string)
  }
}
