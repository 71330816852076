import { Competence } from '@/models/grades/competence'

export class CompetenceDeserializer {
  deserializeMany(json: any[]): Competence[] {
    return json.map((x) => this.deserialize(x))
  }

  deserialize(json: any): Competence {
    return new Competence(json.id as number, json.name as string, json.logo_url as string)
  }
}
