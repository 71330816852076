















import Vue, { PropType } from 'vue'
import { BreadcrumbItem } from './breadcrumb-item'
import { RouteName } from '@/router/route-name'

export default Vue.extend({
  name: 'Breadcrumb',

  props: {
    crumbs: {
      type: Array as PropType<BreadcrumbItem[]>,
      default: new Array<BreadcrumbItem>(),
    },
  },

  data() {
    return {
      homeRouteName: RouteName.HOME,
    }
  },
})
