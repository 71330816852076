




































import Vue from 'vue'
import Form from 'vform'
import { mapGetters } from 'vuex'
import { MetaInfo } from 'vue-meta'
import { AuthStoreKey } from '@/store/modules/auth-store'

export default Vue.extend({
  metaInfo(): MetaInfo {
    return { title: this.$tc('settings') }
  },

  data() {
    return {
      form: new Form({
        name: '',
        email: '',
      }),
    }
  },

  computed: mapGetters({
    user: AuthStoreKey.GETTER_USER,
  }),

  created() {
    // Fill the form with user data.
    this.form.keys().forEach((key: any) => {
      this.form[key] = this.user[key]
    })
  },
})
