import { Module } from 'vuex'

interface LoadingStoreState {
  isRequestPending: boolean
  isRouterLoading: boolean
}

export enum LoadingStoreKey {
  GETTER_REQUEST_PENDING = 'LOADING_GETTER_REQUEST_PENDING',
  SET_REQUEST_PENDING = 'LOADING_SET_REQUEST_PENDING',
  GETTER_ROUTER_LOADING = 'LOADING_ROUTER_LOADING',
  SET_ROUTER_LOADING = 'LOADING_SET_ROUTER_LOADING',
}

export const LoadingStore: Module<LoadingStoreState, unknown> = {
  state: {
    isRequestPending: false,
    isRouterLoading: false,
  },

  getters: {
    [LoadingStoreKey.GETTER_REQUEST_PENDING]: (state) => state.isRequestPending,
    [LoadingStoreKey.GETTER_ROUTER_LOADING]: (state) => state.isRouterLoading,
  },

  mutations: {
    [LoadingStoreKey.SET_REQUEST_PENDING](state, isRequestPending: boolean) {
      state.isRequestPending = isRequestPending
    },
    [LoadingStoreKey.SET_ROUTER_LOADING](state, isRouterLoading: boolean) {
      state.isRouterLoading = isRouterLoading
    },
  },
}
