import 'bootstrap'
import Vue from 'vue'
import { ComponentOptions } from 'vue/types'
import store from './store'
import router from './router'
import i18n from './plugins/i18n'
import App from './components/App.vue'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import moment from 'moment'
import 'moment/locale/fr-ca'
import './sass/app.scss'
import './plugins'
import './components'

export class AppConfigurator {
  public static configure() {
    Vue.config.productionTip = false
    Vue.use(BootstrapVue)
    Vue.use(BootstrapVueIcons)
    moment.locale('fr-ca')
    moment.defaultFormat = 'll'
  }

  public static getVueOptions(): ComponentOptions<Vue> {
    return {
      i18n: i18n,
      store: store,
      router: router,
      render: (h) => h(App),
    }
  }
}
