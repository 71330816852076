






























import Vue from 'vue'
import { Logger } from '@/modules/logger'
import { EvaluationService } from '@/services/evaluation/evaluation-service'
import moment from 'moment'
import { EvaluationStatus } from '@/models/evaluation-status'
import { MetaInfo } from 'vue-meta'
import { Evaluation } from '@/services/evaluation/types'
import BannerNotEnrolled from '@/components/banners/BannerNotEnrolled.vue'
import { AuthStoreKey } from '@/store/modules/auth-store'
import { RouteName } from '@/router/route-name'

export default Vue.extend({
  components: {
    BannerNotEnrolled,
  },

  data() {
    const self = this
    return {
      fields: [
        {
          key: 'name',
          label: self.$t('evaluation_label'),
        },
        {
          key: 'startDatetime',
          label: self.$t('general_begin'),
        },
        {
          key: 'endDatetime',
          label: self.$t('general_finish'),
        },
        {
          key: 'status',
          label: self.$t('status'),
          class: 'text-center',
        },
      ],
      isBusy: false,
      cancelFetchData: () => {},
    }
  },

  computed: {
    currentCourseId(): number | null {
      return this.$store.getters[AuthStoreKey.GETTER_CURRENT_COURSE]?.id ?? null
    },
  },

  metaInfo(): MetaInfo {
    return {
      title: this.$tc('evaluations_admin_page_title'),
    }
  },

  destroyed() {
    this.cancelFetchData()
  },

  methods: {
    fetchData() {
      const cancellablePromise = new EvaluationService().getCourseEvaluations(this.currentCourseId!)
      this.cancelFetchData = cancellablePromise.cancel
      return cancellablePromise.promise
        .then((rawEvaluations) => {
          const evaluations = rawEvaluations.map((x) => {
            return this.responseEvaluationToRowData(x)
          })
          return evaluations
        })
        .catch((error) => {
          Logger.error(error)
          return []
        })
    },

    getEvaluationDetailPageRouterTo(evaluationId: number): {} {
      return {
        name: RouteName.ADMIN_EVALUATION_DETAIL,
        params: { evaluationId: evaluationId.toString() },
      }
    },

    responseEvaluationToRowData(ev: Evaluation) {
      const endDatetime = ev.end_datetime ? moment(ev.end_datetime).format() : ''
      const startDatetime = ev.start_datetime ? moment(ev.start_datetime).format() : ''
      const status = this.$t(EvaluationStatus.codeToTranslationKey(ev.status_code))
      return {
        id: ev.id,
        name: ev.name,
        startDatetime: startDatetime,
        endDatetime: endDatetime,
        status: status,
      }
    },
  },
})
