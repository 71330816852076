

























import Vue, { PropType } from 'vue'
import { default as ldGet } from 'lodash/get'
import isNil from 'lodash/isNil'
import { User } from '@/services/user/user'
import { QuestionPreamble } from '@/services/questionnaire/types'
import { QuestionnaireQuestion } from '@/pages/questionnaire/questionnaire-section'

export default Vue.extend({
  name: 'Question',
  props: {
    question: {
      type: Object as PropType<QuestionnaireQuestion>,
      required: true,
    },
    currentUser: {
      type: Object as PropType<User>,
      required: true,
    },
    availablePreambles: {
      type: Array as PropType<QuestionPreamble[]>,
      required: true,
    },
  },
  data() {
    return {}
  },
  computed: {
    preamble(): string | null {
      const preambleId = ldGet(this.question, 'data.question.question_preamble_id')
      if (isNil(preambleId)) {
        return null
      }

      const preamble = this.availablePreambles.find((x) => {
        return x.id === preambleId
      })
      return preamble!.preamble_html
    },
    title(): string | null {
      return this.question.title
    },
    questionText(): string {
      return this.question.data.question.text
    },
    evaluateeName(): string | null {
      const evaluateeId = this.question.data.evaluatee_id
      const currentUserId = this.currentUser.id
      if (isNil(evaluateeId) || evaluateeId === currentUserId) {
        return null
      }
      return this.question.data.minimal_evaluatee.name
    },
  },
})
