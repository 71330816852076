


































import Vue from 'vue'
import { MetaInfo } from 'vue-meta'
import { Logger } from '@/modules/logger'
import BannerNotEnrolled from '@/components/banners/BannerNotEnrolled.vue'
import { AuthStoreKey } from '@/store/modules/auth-store'
import { UserService } from '@/services/user/user-service'
import { UserRoleUtil, UserRole } from '@/services/user/user-roles'
import { User } from '@/services/user/user'
import { RouteName } from '@/router/route-name'

export default Vue.extend({
  components: {
    BannerNotEnrolled,
  },

  data() {
    const self = this
    return {
      users: new Array<User>(),
      fields: [
        {
          key: 'name',
          label: self.$t('name'),
          class: 'align-middle',
          sortable: true,
        },
        {
          key: 'role',
          label: self.$t('general_user_role'),
          class: 'align-middle',
          sortable: true,
        },
      ],
      isLoadingData: false,
      cancelFetchData: () => {},
      createUserModalId: 'createUserModalId',
    }
  },

  computed: {
    currentCourseId(): number | null {
      return this.$store.getters[AuthStoreKey.GETTER_CURRENT_COURSE]?.id ?? null
    },
  },

  metaInfo(): MetaInfo {
    return {
      title: this.$tc('inscription_list_page_title'),
    }
  },

  created() {
    this.fetchData()
  },

  destroyed() {
    this.cancelFetchData()
  },

  methods: {
    fetchData() {
      this.isLoadingData = true
      const cancellablePromise = new UserService().getUsers(this.currentCourseId!)
      this.cancelFetchData = cancellablePromise.cancel
      return cancellablePromise.promise
        .catch((error) => {
          Logger.error(error)
          return []
        })
        .then((users) => {
          this.users = users
          this.isLoadingData = false
        })
    },

    roleCodeToLabel(code: UserRole) {
      return this.$tc(UserRoleUtil.codeToTranslationKey(code))
    },

    getUserDetailPageRouterTo(userId: number): {} {
      return {
        name: RouteName.USER_DETAIL,
        params: { userId: userId.toString() },
      }
    },

    showCreateUserModal() {
      this.$bvModal.show(this.createUserModalId)
    },

    onUserCreated(user: User) {
      this.users.push(user)
    },
  },
})
