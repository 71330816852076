










































































import Vue from 'vue'
import { MetaInfo } from 'vue-meta'
import { AuthService } from '@/services/auth/auth-service'
import { ServerError } from '@/services/common/server-error'
import { AuthStoreKey } from '@/store/modules/auth-store'
import { RouteName } from '@/router/route-name'

export default Vue.extend({
  metaInfo(): MetaInfo {
    return { title: this.$tc('login') }
  },

  data() {
    return {
      email: '',
      password: '',
      isLoading: false,
      serverErrors: new Array<string>(),
    }
  },

  methods: {
    async onSubmit() {
      this.loadingStarted()
      this.serverErrors = []
      try {
        const tokenOrError = await new AuthService().login(this.email, this.password)
        if (tokenOrError instanceof ServerError) {
          const isUnauthorized = tokenOrError.response?.status === 401
          const errorMessageKey = isUnauthorized ? 'login_unauthorized' : 'general_unexpected_error'
          this.serverErrors.push(this.$tc(errorMessageKey))
        } else {
          await this.$store.dispatch(AuthStoreKey.INIT_SESSION, tokenOrError)
          this.$router.push({ name: RouteName.HOME })
        }
      } finally {
        this.loadingEnded()
      }
    },

    loadingStarted() {
      this.isLoading = true
    },

    loadingEnded() {
      this.isLoading = false
    },
  },
})
