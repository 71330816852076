import { VueConstructor } from 'vue/types/umd'
import LayoutNavContent from './LayoutNavContent.vue'
import LayoutNavSidebarContent from './LayoutNavSidebarContent.vue'
import { LayoutName } from './layout-name'

export type Layout = VueConstructor<Vue>

export const layouts = new Map<LayoutName, Layout>()
layouts.set(LayoutName.NavContent, LayoutNavContent)
layouts.set(LayoutName.NavSidebarContent, LayoutNavSidebarContent)
