







import Vue from 'vue'
import { appInfo } from '@/app-info'

export default Vue.extend({
  data() {
    return {
      diagnosticInfo: getDiagnosticInfo(),
    }
  },
})

function getDiagnosticInfo() {
  const diagnosticInfo = {
    ...appInfo,
    'navigator.userAgent': navigator.userAgent,
    'location.href': location.href,
    'screen.width': screen.width,
    'screen.height': screen.height,
  }
  return JSON.stringify(diagnosticInfo, undefined, 2)
}
