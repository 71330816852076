import Vue from 'vue'
import Vuex from 'vuex'

import { AuthStore } from './modules/auth-store'
import { LayoutStore } from './modules/layout-store'
import { LoadingStore } from './modules/loading-store'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth: AuthStore,
    layout: LayoutStore,
    loading: LoadingStore,
  },
})
