export const messagesFr = {
  code: 'fr',
  messages: {
    alpha: 'Ce champ ne peut contenir que des lettres',
    alpha_num: 'Ce champ ne peut contenir que des caractères alpha-numériques',
    alpha_dash:
      'Ce champ ne peut contenir que des caractères alpha-numériques, tirets ou soulignés',
    alpha_spaces: 'Ce champ ne peut contenir que des lettres ou des espaces',
    between: 'Ce champ doit être compris entre {min} et {max}',
    confirmed: 'Ce champ ne correspond pas',
    digits: 'Ce champ doit être un nombre entier de {length} chiffres',
    dimensions: 'Ce champ doit avoir une taille de {width} pixels par {height} pixels',
    email: 'Ce champ doit être une adresse e-mail valide',
    excluded: 'Ce champ doit être une valeur valide',
    ext: 'Ce champ doit être un fichier valide',
    image: 'Ce champ doit être une image',
    integer: 'Ce champ doit être un entier',
    length: 'Ce champ doit contenir {length} caractères',
    max_value: 'Ce champ doit avoir une valeur de {max} ou moins',
    max: 'Ce champ ne peut pas contenir plus de {length} caractères',
    mimes: 'Ce champ doit avoir un type MIME valide',
    min_value: 'Ce champ doit avoir une valeur de {min} ou plus',
    min: 'Ce champ doit contenir au minimum {length} caractères',
    numeric: 'Ce champ ne peut contenir que des chiffres',
    oneOf: 'Ce champ doit être une valeur valide',
    regex: 'Ce champ est invalide',
    required: 'Ce champ est obligatoire',
    required_if: 'Ce champ est obligatoire lorsque {target} possède cette valeur',
    size: 'Ce champ doit avoir un poids inférieur à {size}KB',
  },
}
