import Vue from 'vue'
import { ValidationProvider, ValidationObserver, localize, configure, extend } from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'
import { messagesFr } from './locale/fr'

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)

for (const [rule, validation] of Object.entries(rules)) {
  extend(rule, {
    ...validation,
  })
}
localize('fr', messagesFr)

const veeValidateConfig = {
  classes: {
    valid: '', // Don't indicate valid fields
    invalid: 'is-invalid',
  },
}
configure(veeValidateConfig)
