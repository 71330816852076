import { UserRole } from './user-roles'
import { Course } from '@/models/course'

export class User {
  public readonly id: number
  public readonly name: string
  public readonly email: string
  public readonly photoUrl: string
  public readonly role: UserRole
  public readonly courses: Course[]

  constructor(
    id: number,
    name: string,
    email: string,
    photoUrl: string,
    role: UserRole,
    courses: Course[]
  ) {
    this.id = id
    this.name = name
    this.email = email
    this.photoUrl = photoUrl
    this.role = role
    this.courses = courses
  }

  public static isAdmin(user?: User): boolean {
    return user?.role === 'admin'
  }
}
