import 'bootstrap'
import Vue from 'vue'
import 'moment/locale/fr-ca'
import './sass/app.scss'
import './plugins'
import './components'
import { appInfo } from './app-info'
import { AppConfigurator } from './app-configurator'

console.log('Running App version ' + appInfo.appVersion)

AppConfigurator.configure()
const vueOptions = AppConfigurator.getVueOptions()
new Vue(vueOptions).$mount('#app')
