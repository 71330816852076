import { Logger } from '@/modules/logger'

export enum UserRole {
  ADMIN = 'admin',
  TUTOR = 'tutor',
  STUDENT = 'student',
}

const codeToTranslationKeyMap = new Map<UserRole, string>()
codeToTranslationKeyMap.set(UserRole.ADMIN, 'user_role_admin')
codeToTranslationKeyMap.set(UserRole.TUTOR, 'user_role_tutor')
codeToTranslationKeyMap.set(UserRole.STUDENT, 'user_role_student')

export class UserRoleUtil {
  static codeToTranslationKey = (code: UserRole) => {
    const key = codeToTranslationKeyMap.get(code)
    if (key === undefined) {
      Logger.warn(`No translation key set for code: ${code}`)
      return code
    }
    return key
  }
}
