export const TranslationsFr = {
  app_name: "Compétences d'Avenir",
  navigation_back: 'Retour',
  model_evaluation_status_not_started: 'À venir',
  model_evaluation_status_open_for_submissions: 'En cours',
  model_evaluation_status_done: 'Terminée',
  evaluation_competence_instruction: 'Veuillez répondre aux questionnaires suivants:',
  evaluation_competence_instruction_fill_auto_eval:
    'Évaluation auto-rapportée en fonction de la perception que vous avez de votre niveau de développement de la compétence',
  evaluation_competence_instruction_fill_questionnaire:
    'Questionnaire d’évaluation de la compétence',
  evaluation_competence_instruction_fill_peer_review:
    'Évaluation des pairs en fonction de la perception que vous avez de leur niveau de développement de la compétence',
  evaluation_competence_instruction_estimated_duration:
    'Temps total estimé: {duration_minutes} minutes',
  evaluation_section_auto_evaluation: 'Auto-évaluation',
  evaluation_section_questionnaire: 'Questionnaire',
  evaluation_section_peer_evaluation: 'Évaluation des pairs',
  evaluations_admin_page_title: 'Évaluations',
  evaluations_admin_download_results: 'Télécharger les résultats',
  evaluations_page_title: 'Évaluations',
  evaluation_detail_page_title: 'Évaluation (détail)',
  evaluation_detail_comments_popup_tooltip: '{count} commentaires',
  evaluation_list_show_report_action: 'Afficher mon bulletin...',
  evaluation_list_completion_indicator_label: 'Questions non-répondues: {unanswered}',
  evaluation_list_header_completed: 'Complétée',
  evaluation_page_title: 'Évaluation',
  evaluation_label: 'Évaluation',
  evaluation_questionnaire_page_title: 'Questionnaire',
  evaluation_published: 'Publiée',
  questionnaire_comment_placeholder: 'Écrire un commentaire',
  questionnaire_validation_ignore_errors_message:
    'Votre réponse comporte des erreurs. Continuer sans sauvegarder votre réponse?',
  questionnaire_limit_datetime_lavel: 'Date limite:',
  questionnaire_generic_question_title: 'Question {index}',
  questionnaire_question_text_placeholder: 'Écrivez votre texte ici',
  questionnaire_question_text_char_count_indicator_label: '{count}/{max} caractères utilisés',
  general_unsaved_data_warning:
    'Certaines données ne sont pas encore sauvegardées. Veuillez attendre un moment et réessayez.',
  general_begin: 'Début',
  general_finish: 'Fin',
  general_results: 'Résultats',
  general_not_applicable: 'n/d',
  general_unavailable_short: 'n/d',
  general_click_for_details: 'Cliquez pour obtenir des détails',
  general_contact_us: 'Nous joindre',
  status: 'Statut',
  ok: 'Ok',
  cancel: 'Annuler',
  general_add: 'Ajouter',
  general_admin_section: 'Administration',
  general_delete: 'Supprimer',
  general_submit: 'Soumettre',
  general_error: 'Erreur',
  general_error_unknown: 'Erreur inconnue',
  general_id: 'Id',
  general_unexpected_error:
    "Une erreur s'est produite. Si le problème persiste, contactez le support technique.",
  general_quit: 'Quitter',
  general_save: 'Sauvegarder',
  general_save_and_quit: 'Sauvegarder & quitter',
  general_actions: 'Actions',
  general_begin_action: 'Commencer',
  general_finish_action: 'Terminer',
  general_prev_action: 'Précédent',
  general_next_action: 'Suivant',
  general_loading: 'Chargement...',
  general_no_results: 'Aucun résultat',
  general_no_data: 'Aucune donnée disponible',
  general_page_in_progress: 'En développement...',
  general_evaluators: 'Évaluateurs',
  general_evaluator: 'Évaluateur',
  general_user_role: 'Rôle',
  general_comments: 'Commentaires',
  general_confirm_message: 'Êtes-vous sûr de vouloir procéder?',
  general_show: 'Afficher',
  general_hide: 'Masquer',
  general_preview: 'Aperçu',
  general_show_preview: "Afficher l'aperçu",
  general_hide_preview: "Masquer l'aperçu",
  general_unsaved_confirmation: 'Vos modifications seront perdues. Continuer?',
  validation_required_short: 'Requis',
  error_alert_title: 'Oops...',
  error_alert_text: "Une erreur s'est produite. Réessayez.",
  error: 'Erreur',
  error_toast_instructions:
    'Réessayez ou contactez le support technique en spécifiant ces informations:',
  token_expired_alert_title: 'Session expirée',
  token_expired_alert_text: 'Veuillez vous re-connecter.',
  login: 'Connexion',
  login_unauthorized: 'Ces identifiants ne correspondent pas à nos enregistrements',
  register: 'Créer un compte',
  page_not_found: 'Page introuvable',
  go_home: "Retour à l'accueil",
  logout: 'Déconnexion',
  email: 'Courriel',
  password: 'Mot de passe',
  forgot_password: 'Mot de passe oublié?',
  confirm_password: 'Confirmer le mot de passe',
  name: 'Nom',
  home: 'Accueil',
  menu: 'Menu',
  action_dropdown_label: 'Actions',
  dashboard: 'Tableau de bord',
  dashboard_competence_avg: 'Moyenne des compétences',
  dashboard_auto_eval_title: 'Moyenne des auto-évaluations',
  dashboard_auto_eval_subtitle: '(auto-perception vs. perception des pairs)',
  dashboard_auto_eval: 'Auto-évaluation',
  dashboard_peers: 'Pairs',
  dashboard_questionnaire_avg_title: 'Moyenne des questionnaires',
  dashboard_questionnaire_avg_subtitle: '(mes résultats vs. moyenne de la cohorte)',
  dashboard_my_results: 'Mes résultats',
  dashboard_class_avg: 'Moyenne de la cohorte',
  dashboard_progression_title: 'Progression',
  admin_various_page_title: 'Divers',
  admin_grades_title: 'Résultats',
  admin_update_grades_general: 'Mise à jour des statistiques des résultats',
  admin_autofill_grades_general: 'Correction automatique (partielle)',
  admin_autofill_evaluation_id_placeholder: "ID d'évaluation",
  admin_login_as: 'Se connecter en tant que...',
  admin_login_as_placeholder: 'ID utilisateur',
  admin_login_as_button: 'Connexion...',
  admin_login_modal_title: 'Instructions',
  admin_login_modal_instructions_1:
    'Ouvrir le lien dans une NOUVELLE FENÊTRE PRIVÉE (évite les conflits entre sessions)',
  admin_login_modal_instructions_2:
    'Une fois terminé, déconnectez-vous pour bien fermer la nouvelle session',
  admin_section_risk_warning:
    'ATTENTION: Les opérations dans cette section peuvent causer la perte de données. Pour utilisateurs avertis.',
  user_list_page_title: 'Utilisateurs',
  user_list_page_table_header: 'Liste de tous les utilisateurs du système',
  user_detail_page_title: 'Utilisateur (détail)',
  user_detail_tab_overview: 'Sommaire',
  report_card: 'Bulletin',
  user_detail_tab_evaluations: 'Évaluations',
  report_card_measurement: 'Mesure',
  report_card_measurement_questionnaire_score: 'Questionnaire (moi)',
  report_card_measurement_questionnaire_avg: 'Questionnaire (moy. du groupe)',
  report_card_measurement_auto_eval: 'Auto-évaluation',
  report_card_measurement_peers_perception: 'Évaluation par les pairs',
  reset_password: 'Réinitialiser le mot de passe',
  send_password_reset_link: 'Envoyer un lien de réinitialisation',
  settings: 'Paramètres',
  profile: 'Profil',
  your_info: 'Mon profil',
  info_updated: 'Information mise à jour',
  update: 'Mettre à jour',
  success: 'Succès',
  failure: 'Échec',
  action_force_update: 'Forcer la mise à jour',
  your_password: 'Mot de passe',
  password_updated: 'Mot de passe mis à jour',
  new_password: 'Nouveau mot de passe',
  contact_us_form_tech_support_heading: 'Soutien technique',
  competences_section_title: 'Compétences',
  diagnostics_page_title: 'Informations pour diagnostique',
  unenrolled_banner_body:
    "Vous n'êtes pas inscrit à un cours. Si c'est une erreur, contactez la personne responsable du cours.",
  user_role_admin: 'Admin',
  user_role_tutor: 'Tuteur⋅trice',
  user_role_student: 'Étudiant⋅e',
  user_anonymous_name: '(Anonyme)',
  user_create_modal_title: 'Créer un utilisateur',
  inscription_list_page_title: 'Inscriptions',
  send_welcome_notification_action_label: 'Envoyer une invitation par courriel',
  competence_list_page_title: 'Compétences',
  competence_detail_page_title: 'Compétence (détail)',
  competence_logo_url: 'URL du logo',
  competence_description: 'Description',
  tech_support_not_set_message: 'Contactez votre professeur ou responsable de cours',
  unpublish_grades: 'Dé-publier les résultats',
  publish_grades: 'Publier les résultats',
  set_publish_grades_warning:
    'Publier/dé-publier les résultats mettera à jour les statistiques de tout le système. Continuer?',
  grades_published_label: 'Résultats publiés',
}
