





































































import Vue from 'vue'
import Form from 'vform'
import { MetaInfo } from 'vue-meta'

export default Vue.extend({
  metaInfo(): MetaInfo {
    return { title: this.$tc('reset_password') }
  },

  data() {
    return {
      status: '',
      form: new Form({
        token: '',
        email: '',
        password: '',
        password_confirmation: '',
      }),
    }
  },

  created() {
    this.form.email = this.$route.query.email
    this.form.token = this.$route.params.token
  },

  methods: {
    async reset() {
      const { data } = await this.form.post('/api/password/reset')

      this.status = data.status

      this.form.reset()
    },
  },
})
