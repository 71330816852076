import store from '@/store'
import { Middleware, MiddlewareOutcome } from './types'
import { AuthStoreKey } from '@/store/modules/auth-store'
import { RouteName } from '@/router/route-name'
import { Route } from 'vue-router'

export class GuestOnlyMiddleware implements Middleware {
  async execute(_from: Route, _to: Route) {
    if (store.getters[AuthStoreKey.GETTER_IS_LOGGED_IN]) {
      return MiddlewareOutcome.stop({ name: RouteName.HOME })
    }
    return MiddlewareOutcome.continue()
  }
}
