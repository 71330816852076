import { Module } from 'vuex'
import { User } from '@/services/user/user'
import { AuthService } from '@/services/auth/auth-service'
import { JwtToken } from '@/services/common/jwt-token'
import { UserService } from '@/services/user/user-service'
import {
  LocalStorageService,
  LocalStorageKey,
} from '@/services/local-storage/local-storage-service'
import { Course } from '@/models/course'
import { Assert } from '@/utils/assert'

interface AuthStoreState {
  user?: User
  token?: JwtToken
  currentCourse: Course | null
}

export enum AuthStoreKey {
  GETTER_USER = 'AUTH_GETTER_USER',
  GETTER_CURRENT_COURSE = 'AUTH_GETTER_CURRENT_COURSE',
  GETTER_COURSES = 'AUTH_GETTER_COURSES',
  GETTER_TOKEN = 'AUTH_GETTER_TOKEN',
  GETTER_IS_LOGGED_IN = 'AUTH_GETTER_IS_LOGGED_IN',
  MUT_LOGOUT = 'AUTH_MUT_LOGOUT',
  ACTION_LOGOUT = 'AUTH_ACTION_LOGOUT',
  ACTION_SET_CURRENT_COURSE = 'AUTH_ACTION_SET_CURRENT_COURSE',
  GET_OR_INIT_USER = 'AUTH_GET_OR_INIT_USER',
  INIT_SESSION = 'AUTH_INIT_SESSION',
}
// Private store keys
const SAVE_TOKEN = 'AUTH_SAVE_TOKEN'
const SET_USER = 'AUTH_SET_USER'
const SET_CURRENT_COURSE = 'AUTH_SET_CURRENT_COURSE'

export const LOGIN_AS_TOKEN_PARAMETER_NAME = 'loginAs'
const localStorageService = new LocalStorageService()

export const AuthStore: Module<AuthStoreState, unknown> = {
  state() {
    const url = new URL(location.href)
    const encodedTokenFromUrl = url.searchParams.get(LOGIN_AS_TOKEN_PARAMETER_NAME)
    const rawTokenFromUrl = encodedTokenFromUrl && decodeURIComponent(encodedTokenFromUrl)
    const rawTokenFromStorage = localStorageService.getItem(LocalStorageKey.JWT_TOKEN)
    const rawToken = rawTokenFromUrl ?? rawTokenFromStorage ?? undefined
    const token = rawToken === undefined ? undefined : JwtToken.deserialize(rawToken)
    return {
      user: undefined,
      token: token,
      currentCourse: null,
    }
  },

  getters: {
    [AuthStoreKey.GETTER_USER]: (state) => state.user,
    [AuthStoreKey.GETTER_COURSES]: (state) => state.user?.courses ?? [],
    [AuthStoreKey.GETTER_CURRENT_COURSE]: (state) => state.currentCourse,
    [AuthStoreKey.GETTER_TOKEN]: (state) => state.token,
    [AuthStoreKey.GETTER_IS_LOGGED_IN]: (state) => state.token !== undefined,
  },

  mutations: {
    [SAVE_TOKEN](state, token: JwtToken) {
      state.token = token
      localStorageService.setItem(LocalStorageKey.JWT_TOKEN, token.serialize())
    },

    [SET_USER](state, user: User) {
      state.user = user
    },

    [SET_CURRENT_COURSE](state, course: Course | null) {
      state.currentCourse = course
    },

    [AuthStoreKey.MUT_LOGOUT](state) {
      state.user = undefined
      state.token = undefined
      localStorageService.clear()
    },
  },

  actions: {
    async [AuthStoreKey.INIT_SESSION](context, jwtToken: JwtToken) {
      context.commit(SAVE_TOKEN, jwtToken)
    },

    async [AuthStoreKey.GET_OR_INIT_USER](context) {
      const currentValue = context.state.user
      if (currentValue !== undefined) {
        return currentValue
      }
      const user = await new UserService().getCurrentUser()
      context.commit(SET_USER, user)

      const preferredCourseId = Number(
        localStorageService.getItem(LocalStorageKey.DEFAULT_COURSE_ID)
      )
      const currentCourse =
        user?.courses?.find((x) => x.id === preferredCourseId) ?? user?.courses[0] ?? null
      context.dispatch(AuthStoreKey.ACTION_SET_CURRENT_COURSE, currentCourse)
    },

    async [AuthStoreKey.ACTION_SET_CURRENT_COURSE](context, course: Course | null) {
      const courses = context.getters[AuthStoreKey.GETTER_COURSES] as Course[]
      Assert.isTrue(course === null || courses.includes(course))
      if (course?.id) {
        localStorageService.setItem(LocalStorageKey.DEFAULT_COURSE_ID, course?.id.toString())
      }
      context.commit(SET_CURRENT_COURSE, course)
    },

    async [AuthStoreKey.ACTION_LOGOUT]({ commit }) {
      await new AuthService().logout()
      commit(AuthStoreKey.MUT_LOGOUT)
    },
  },
}
