import { BigDecimal } from '@/utils/number-utils'

export class CompetenceDoubleRating {
  public ratingPrimary: BigDecimal | null
  public ratingSecondary: BigDecimal | null
  public competenceName: string

  constructor(
    competenceName: string,
    ratingPrimary: BigDecimal | null,
    ratingSecondary: BigDecimal | null
  ) {
    this.competenceName = competenceName
    this.ratingPrimary = ratingPrimary
    this.ratingSecondary = ratingSecondary
  }
}
