import axios from 'axios'
import { Logger } from '@/modules/logger'
import { ServerError } from '../common/server-error'
import { JwtToken } from '../common/jwt-token'

export class AuthService {
  public async login(email: string, password: string) {
    const payload = {
      email: email,
      password: password,
    }
    return axios.post('/api/auth/login', payload).then(
      (response) => {
        const data = response.data
        return new JwtToken(data.access_token, new Date(data.expires_at))
      },
      (error) => ServerError.from(error)
    )
  }

  public async loginAs(userId: number) {
    const payload = {
      userId: userId,
    }
    return axios.post('/api/auth/loginAs', payload).then((response) => {
      const data = response.data
      return new JwtToken(data.access_token, new Date(data.expires_at))
    })
  }

  public async logout() {
    try {
      await axios.post('/api/auth/logout')
    } catch (e) {
      Logger.warn('Exception during logout: ', e)
    }
  }
}
