import { render, staticRenderFns } from "./ReportCard.vue?vue&type=template&id=09e34526&scoped=true&"
import script from "./ReportCard.vue?vue&type=script&lang=ts&"
export * from "./ReportCard.vue?vue&type=script&lang=ts&"
import style0 from "./ReportCard.vue?vue&type=style&index=0&id=09e34526&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "09e34526",
  null
  
)

export default component.exports