import { Module } from 'vuex'
import { LayoutName } from '@/layouts/layout-name'

export const SET_LAYOUT = 'SET_LAYOUT'

interface LayoutState {
  layoutName: LayoutName
}

export const LayoutStore: Module<LayoutState, unknown> = {
  state: {
    layoutName: LayoutName.NavContent,
  },

  mutations: {
    [SET_LAYOUT](state, layoutName: LayoutName) {
      state.layoutName = layoutName
    },
  },
}
