












import Vue from 'vue'

export default Vue.extend({
  props: {
    loading: {
      type: Boolean,
      required: true,
      default: false,
    },
  },

  data() {
    return {
      percent: 0,
      show: false,
      duration: 3000,
      height: '2px',
      color: '#77b6ff',
      timer: undefined as number | undefined,
      cut: NaN,
    }
  },

  watch: {
    loading(value) {
      value ? this.start() : this.finish()
    },
  },

  methods: {
    start() {
      this.show = true
      if (this.timer) {
        clearInterval(this.timer)
        this.percent = 0
      }
      this.cut = 10000 / Math.floor(this.duration)
      this.timer = setInterval(() => {
        this.increase(this.cut * Math.random())
        if (this.percent > 95) {
          this.finish()
        }
      }, 100)
      return this
    },
    set(num: number) {
      this.show = true
      this.percent = Math.floor(num)
      return this
    },
    get() {
      return Math.floor(this.percent)
    },
    increase(num: number) {
      this.percent = this.percent + Math.floor(num)
      return this
    },
    finish() {
      this.percent = 100
      this.hide()
      return this
    },
    hide() {
      clearInterval(this.timer)
      this.timer = undefined
      setTimeout(() => {
        this.show = false
        this.$nextTick(() => {
          setTimeout(() => {
            this.percent = 0
          }, 200)
        })
      }, 500)
      return this
    },
  },
})
