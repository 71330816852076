import { Respondent, PeerCommentType } from '@/services/evaluation/types'

export class PeerComment {
  public readonly evaluator: Respondent
  public readonly role: string
  public readonly comments: PeerCommentType[]

  constructor(evaluator: Respondent, role: string, comments: PeerCommentType[]) {
    this.evaluator = evaluator
    this.role = role
    this.comments = comments
  }
}
