























































import Vue, { PropType } from 'vue'
import { ValidationObserverInstance } from '@/utils/misc-types'
import { UserRoleUtil, UserRole } from '@/services/user/user-roles'
import { UserService } from '@/services/user/user-service'
import { ServerError } from '@/services/common/server-error'
import { Assert } from '@/utils/assert'
import { User } from '@/services/user/user'

const userService = new UserService()

type RoleOption = {
  value: UserRole
  text: string
}

export default Vue.extend({
  props: {
    modalId: {
      type: String,
      required: true,
    },
    onCreated: {
      type: Function as PropType<(user: User) => void>,
      required: true,
    },
  },

  data() {
    return {
      name: '',
      email: '',
      role: UserRole.STUDENT,
      serverErrors: new Array<string>(),
      isLoading: false,
    }
  },

  computed: {
    roleOptions(): RoleOption[] {
      return this.getRoleOptions()
    },
  },

  methods: {
    resetModal() {
      this.name = ''
      this.email = ''
      this.role = UserRole.STUDENT
      this.serverErrors = []
      this.isLoading = false
    },

    handleOk(bvModalEvt: Event) {
      bvModalEvt.preventDefault()
      this.trySubmit()
    },

    async trySubmit() {
      const formIsValid = await this.isFormValid()
      if (formIsValid) {
        await this.doSubmit()
      }
    },

    async doSubmit() {
      this.isLoading = true
      this.serverErrors = []
      try {
        const userOrError = await userService.create(this.name, this.email, this.role)
        if (userOrError instanceof ServerError) {
          const error = Assert.isInstanceOf(userOrError, ServerError)
          this.serverErrors = error.errorsAsSimpleFormat()
        } else {
          const user = Assert.isInstanceOf(userOrError, User)
          this.closeModal()
          this.onCreated(user)
        }
      } finally {
        this.isLoading = false
      }
    },

    isFormValid(): Promise<boolean> {
      return (this.$refs.veeObserver as ValidationObserverInstance).validate()
    },

    closeModal() {
      this.$nextTick(() => {
        this.$bvModal.hide(this.modalId)
      })
    },

    getRoleOptions(): RoleOption[] {
      const allRoleCodes = Object.values(UserRole)
      return allRoleCodes.map((code) => ({
        value: code,
        text: this.$tc(UserRoleUtil.codeToTranslationKey(code)),
      }))
    },
  },
})
