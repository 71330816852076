
import Vue from 'vue'
import { Line, mixins } from 'vue-chartjs'
import { ChartOptions } from 'chart.js'

const defaultOptions = {
  aspectRatio: 3,
  scales: {
    yAxes: [
      {
        ticks: {
          min: 0,
          max: 100,
        },
      },
    ],
  },
}

export default Vue.extend({
  extends: Line,

  mixins: [mixins.reactiveProp],

  props: {
    customOptions: {
      type: Object,
      required: false,
      default: () => new Object(),
    },
  },

  computed: {
    options(): ChartOptions {
      return {
        ...defaultOptions,
        ...this.customOptions,
      }
    },
  },

  mounted() {
    const vm = this as any
    vm.renderChart(vm.chartData, this.options)
  },
})
