
















import Vue from 'vue'
import Navbar from '@/components/Navbar.vue'
import Sidebar from '@/components/Sidebar.vue'

export default Vue.extend({
  components: {
    Navbar,
    Sidebar,
  },
})
