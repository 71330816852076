import axios from 'axios'
import { AxiosUtils } from '@/utils/axios-utils'
import { CancellablePromise } from '@/utils/promise/cancellable-promise'
import { GradesDeserializer } from '@/services/grade-stats/grades-deserializer'
import { Grades } from '@/models/grades/grades'

const gradesDeserializer = new GradesDeserializer()

export class GradeStatsService {
  public getAllStats(courseId: number, targetUserId: number): CancellablePromise<Grades | null> {
    const cancelSource = axios.CancelToken.source()
    const promise = axios
      .get(`/api/courses/${courseId}/grades`, {
        params: {
          targetUserId: targetUserId,
        },
        cancelToken: cancelSource.token,
      })
      .then((response) => {
        return gradesDeserializer.deserialize(response.data)
      }, AxiosUtils.returnValueIfCancelledOrRethrow(null))

    return new CancellablePromise(promise, cancelSource.cancel)
  }

  public doAutofill(evaluationId: number): Promise<void> {
    return axios.post(`/api/grades/do-autofill/${evaluationId}`).then(() => {})
  }

  public updateStats(): Promise<void> {
    return axios.post('/api/grades/update-stats').then(() => {})
  }
}
