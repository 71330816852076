import { Route, RawLocation } from 'vue-router'

type NextFnInput = RawLocation | false | Error | void

export class MiddlewareOutcome {
  public readonly stop: boolean = false
  public readonly argsForNextFn: NextFnInput = undefined

  private constructor(stop: boolean, argsForNextFn: NextFnInput) {
    this.stop = stop
    this.argsForNextFn = argsForNextFn
  }

  public static continue() {
    return new MiddlewareOutcome(false)
  }

  public static stop(argsForNextFn: RawLocation | false) {
    return new MiddlewareOutcome(true, argsForNextFn)
  }
}

export interface Middleware {
  execute(from: Route, to: Route): Promise<MiddlewareOutcome>
}
