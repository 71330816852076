export class Competence {
  public id: number
  public name: string
  public logoUrl: string

  constructor(id: number, name: string, logoUrl: string) {
    this.id = id
    this.name = name
    this.logoUrl = logoUrl
  }
}
