type Newable<T> = { new (...args: any[]): T }

export class Assert {
  public static isTrue(condition: boolean, message?: string) {
    if (condition !== true) {
      throw Error(message ?? 'Expected condition to be true')
    }
  }

  public static isDefined<T>(value: T | undefined, message?: string) {
    if (value === undefined) {
      throw Error(message ?? 'Expected value to be defined')
    }
    return value
  }

  public static isInstanceOf<T, U>(value: T, clazz: Newable<U>, message?: string) {
    if (!(value instanceof clazz)) {
      throw Error(message ?? `Expected value to be an instance of ${clazz}`)
    }
    return value
  }

  public static notNullOrUndefined<T>(value: T | undefined | null, message?: string) {
    if (value === undefined || value === null) {
      throw Error(message ?? 'Expected value to be defined and non-null')
    }
    return value
  }
}
