// NOTE: route name follows a schemantic naming. For instance, 'x.y' is a parent route to 'x.y.z'
export enum RouteName {
  HOME = 'home',
  ADMIN_EVALUATION_LIST = 'admin-evaluations',
  ADMIN_EVALUATION_DETAIL = 'admin-evaluation.detail',
  ADMIN_VARIOUS = 'admin-various',
  INSCRIPTION_LIST = 'inscriptions',
  USER_LIST = 'users',
  USER_DETAIL = 'inscriptions.detail',
  EVALUATION_LIST = 'evaluations',
  EVALUATION_DETAIL = 'evaluations.detail',
  COMPETENCE_LIST = 'competences',
  COMPETENCE_DETAIL = 'competences.detail',
}
