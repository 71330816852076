import axios from 'axios'
import { ReportCardCustomMetricEntry } from './types'
import { AxiosUtils } from '@/utils/axios-utils'
import { CancellablePromise } from '@/utils/promise/cancellable-promise'

export class ReportCardService {
  public getCustomMetrics(
    courseId: number,
    userId: number
  ): CancellablePromise<ReportCardCustomMetricEntry[]> {
    const cancelSource = axios.CancelToken.source()
    const promise = axios
      .get<ReportCardCustomMetricEntry[]>('/api/report-card/custom-metrics', {
        cancelToken: cancelSource.token,
        params: {
          courseId: courseId,
          userId: userId,
        },
      })
      .then((response) => response.data, AxiosUtils.returnValueIfCancelledOrRethrow([]))
    return new CancellablePromise(promise, cancelSource.cancel)
  }
}
