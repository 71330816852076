
















































import Vue from 'vue'
import moment from 'moment'
import { MetaInfo } from 'vue-meta'
import { Logger } from '@/modules/logger'
import { BreadcrumbItem } from '@/components/breadcrumb/breadcrumb-item'
import DashboardStudent from '@/components/dashboard/DashboardStudent.vue'
import ReportCard from '@/components/report-card/ReportCard.vue'
import EvaluationDetail from '@/components/evaluation-detail/EvaluationDetail.vue'
import { AuthStoreKey } from '@/store/modules/auth-store'
import { RouteName } from '@/router/route-name'
import { UserService } from '@/services/user/user-service'
import { User } from '@/services/user/user'
import { EvaluationService } from '@/services/evaluation/evaluation-service'
import { Evaluation } from '@/services/evaluation/types'
import { EvaluationStatus } from '@/models/evaluation-status'

export default Vue.extend({
  components: {
    DashboardStudent,
    ReportCard,
    EvaluationDetail,
  },

  props: {
    userId: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      breadcrumbItems: getBreadcrumbItems(this.$t.bind(this)),
      isLoadingUser: false,
      isLoadingEvaluations: false,
      user: null as User | null,
      fields: [
        {
          key: 'name',
          label: this.$t('evaluation_label'),
        },
        {
          key: 'startDatetime',
          label: this.$t('general_begin'),
        },
        {
          key: 'endDatetime',
          label: this.$t('general_finish'),
        },
        {
          key: 'status',
          label: this.$t('status'),
          class: 'text-center',
        },
      ],
      evaluations: [] as any[],
      cancelFetchData: () => {},
      detailEvaluationId: null as number | null,
      showDetail: false,
    }
  },

  computed: {
    currentCourseId(): number | null {
      return this.$store.getters[AuthStoreKey.GETTER_CURRENT_COURSE]?.id ?? null
    },
    isLoading(): boolean {
      return this.isLoadingUser || this.isLoadingEvaluations
    },
    userName(): string {
      return this.user?.name ?? ''
    },
  },

  created() {
    this.fetchData()
  },

  metaInfo(): MetaInfo {
    return {
      title: this.$tc('user_detail_page_title'),
    }
  },

  methods: {
    fetchData() {
      this.fetchUser()
      this.fetchEvaluations()
    },

    fetchUser() {
      this.isLoadingUser = true
      new UserService()
        .getUser(this.userId)
        .then(
          (user) => {
            this.user = user
          },
          (error) => {
            Logger.error(error)
          }
        )
        .then(() => {
          this.isLoadingUser = false
        })
    },

    fetchEvaluations() {
      this.isLoadingEvaluations = true
      const cancellablePromise = new EvaluationService().getUserEvaluations(
        this.currentCourseId!,
        this.userId
      )
      this.cancelFetchData = cancellablePromise.cancel
      cancellablePromise.promise
        .then((rawEvaluations) => {
          const evaluations = rawEvaluations.map((x) => {
            return this.responseEvaluationToRowData(x)
          })
          this.evaluations = evaluations
        })
        .catch((error) => {
          Logger.error(error)
        })
        .then(() => {
          this.isLoadingEvaluations = false
        })
    },

    toDetail(evaluationId: number) {
      this.detailEvaluationId = evaluationId
      this.showDetail = true
    },

    exitDetail() {
      this.showDetail = false
    },

    responseEvaluationToRowData(ev: Evaluation) {
      const endDatetime = ev.end_datetime ? moment(ev.end_datetime).format() : ''
      const startDatetime = ev.start_datetime ? moment(ev.start_datetime).format() : ''
      const status = this.$t(EvaluationStatus.codeToTranslationKey(ev.status_code))
      return {
        id: ev.id,
        name: ev.name,
        startDatetime: startDatetime,
        endDatetime: endDatetime,
        status: status,
      }
    },
  },
})

function getBreadcrumbItems($t: Function): BreadcrumbItem[] {
  return [
    new BreadcrumbItem(
      $t('inscription_list_page_title'),
      { name: RouteName.INSCRIPTION_LIST },
      false
    ),
    new BreadcrumbItem($t('user_detail_page_title'), null, true),
  ]
}
