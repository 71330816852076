import { Logger } from '@/modules/logger'
import isNil from 'lodash/isNil'
import {
  ExtendedEvaluationQuestion,
  EvaluationAnswer as BaseEvaluationAnswer,
  AvailableChoice,
} from '@/services/questionnaire/types'

export type LodashDebounceFlushFunction<T> = () => T | undefined
export type LodashDebounce<T extends (...args: any) => any> = T & {
  flush(): void
}

export class EvaluationAnswer {
  static readonly TEXT_MAX_LENGTH = 10000

  readonly data: Partial<BaseEvaluationAnswer>
  readonly availableChoices: AvailableChoice[]
  isDirty: boolean
  inputTypeCode: string
  debouncedSaveAnswer: LodashDebounce<() => Promise<boolean> | void> | undefined

  constructor(
    data: Partial<BaseEvaluationAnswer>,
    inputTypeCode: string,
    availableChoices: AvailableChoice[]
  ) {
    this.data = data
    this.inputTypeCode = inputTypeCode
    this.availableChoices = availableChoices
    this.isDirty = false
  }

  isSaved() {
    return !isNil(this.data.id)
  }

  isEmpty() {
    return (
      isNil(this.data.value_text) &&
      isNil(this.data.value_decimal) &&
      isNil(this.data.available_choice_id)
    )
  }

  isCompleted() {
    return !this.isEmpty()
  }

  static getAnswerDataFromQuestionData = function (
    questionData: ExtendedEvaluationQuestion
  ): Partial<BaseEvaluationAnswer> {
    return questionData.answers[0] || createAnswerData(questionData.id)
  }
}

function createAnswerData(questionId: number): Partial<BaseEvaluationAnswer> {
  return {
    question_id: questionId,
    value_text: null,
    value_decimal: null,
    comment: null,
    available_choice_id: null,
  }
}

export class QuestionnaireQuestion {
  readonly answerInputTypeCode: string
  readonly data: ExtendedEvaluationQuestion
  readonly title: string
  readonly answer: EvaluationAnswer

  constructor(
    data: ExtendedEvaluationQuestion,
    answerInputTypeCode: string,
    answer: EvaluationAnswer,
    $t: Function
  ) {
    this.data = data

    const titleSuffix = this.isPeerEvaluation() ? ` (${data.minimal_evaluatee.name})` : ''
    const fallbackQuestionTitle = $t('questionnaire_generic_question_title', { index: data.index })
    const title = (data.question.title || fallbackQuestionTitle) + titleSuffix
    this.title = title
    this.answerInputTypeCode = answerInputTypeCode
    this.answer = answer
  }

  private isAutoEvaluation() {
    return this.data.evaluatee_id === this.data.respondent_id // TODO: assumes that current user is evaluatee
  }

  private isPeerEvaluation() {
    return !isNil(this.data.evaluatee_id) && this.data.evaluatee_id !== this.data.respondent_id // TODO: assumes that current user is evaluatee
  }

  getCommentRules(): {} {
    const baseRules = { max: 10000 }
    const autoEvaluationRules = {
      required: true,
      min: 100,
    }

    const result = { ...baseRules }
    if (this.isAutoEvaluation()) {
      Object.assign(result, autoEvaluationRules)
    }

    return result
  }
}

const CODES = {
  AUTO_EVAL: 'AUTO_EVAL',
  QUESTIONNAIRE: 'QUESTIONNAIRE',
  PEER_EVAL: 'PEER_EVAL',
}

const CODE_TO_TRANS_KEY = new Map()
CODE_TO_TRANS_KEY.set(CODES.AUTO_EVAL, 'evaluation_section_auto_evaluation')
CODE_TO_TRANS_KEY.set(CODES.QUESTIONNAIRE, 'evaluation_section_questionnaire')
CODE_TO_TRANS_KEY.set(CODES.PEER_EVAL, 'evaluation_section_peer_evaluation')

export class QuestionnaireSection {
  static readonly CODES = CODES

  readonly id: number
  readonly code: string
  readonly index: number
  readonly title: string
  readonly questions: QuestionnaireQuestion[]

  constructor(id: number, code: string, index: number, title: string) {
    this.id = id
    this.code = code
    this.index = index
    this.title = title
    this.questions = []
  }

  addQuestions(questions: QuestionnaireQuestion[]) {
    this.questions.push(...questions)
  }

  static questionUsersToCode(userId: number, _respondentId: number, evaluateeId: number | null) {
    if (evaluateeId === userId) {
      return CODES.AUTO_EVAL
    }
    if (isNil(evaluateeId)) {
      return CODES.QUESTIONNAIRE
    }
    return CODES.PEER_EVAL
  }

  static codeToTransKey(code: string) {
    const found = CODE_TO_TRANS_KEY.get(code)
    if (!found) {
      Logger.warn(`No translation key found for code ${code}`)
      return code
    }
    return found
  }
}
