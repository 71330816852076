import store from '@/store'
import { Route } from 'vue-router'
import { Middleware, MiddlewareOutcome } from './types'
import { User } from '@/services/user/user'
import { AuthStoreKey } from '@/store/modules/auth-store'

export class AdminMiddleware implements Middleware {
  async execute(_from: Route, _to: Route) {
    if (User.isAdmin(store.getters[AuthStoreKey.GETTER_USER])) {
      return MiddlewareOutcome.continue()
    }
    return MiddlewareOutcome.stop(Error('Unauthorized to access admin section'))
  }
}
