import { Logger } from '@/modules/logger'
import { EvaluationStatusCode } from '@/services/evaluation/types'

const codeToTranslationKeyMap = new Map()
codeToTranslationKeyMap.set(EvaluationStatusCode.NOT_STARTED, 'model_evaluation_status_not_started')
codeToTranslationKeyMap.set(
  EvaluationStatusCode.OPEN_FOR_SUBMISSIONS,
  'model_evaluation_status_open_for_submissions'
)
codeToTranslationKeyMap.set(EvaluationStatusCode.DONE, 'model_evaluation_status_done')

export class EvaluationStatus {
  static codeToTranslationKey = (code: EvaluationStatusCode) => {
    const key = codeToTranslationKeyMap.get(code)
    if (key === undefined) {
      Logger.warn(`No translation key set for code: ${code}`)
      return code
    }
    return key
  }
}
