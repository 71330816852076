














import Vue from 'vue'
import { RouteName } from '@/router/route-name'
export default Vue.extend({
  name: 'NotFound',
  data() {
    return {
      homeRouteName: RouteName.HOME,
    }
  },
})
