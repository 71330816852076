












































import Vue from 'vue'
import { MetaInfo } from 'vue-meta'
import { Logger } from '@/modules/logger'
import LoginAsModal from './LoginAsModal.vue'
import { AuthService } from '@/services/auth/auth-service'
import { LOGIN_AS_TOKEN_PARAMETER_NAME } from '@/store/modules/auth-store'
import { Assert } from '@/utils/assert'
import { GradeStatsService } from '@/services/grade-stats/grade-stats-service'

const LOGIN_AS_MODAL_ID = 'loginAsModalId'

export default Vue.extend({
  components: {
    LoginAsModal,
  },

  metaInfo(): MetaInfo {
    return { title: this.$t('admin_various_page_title') as string }
  },

  data() {
    return {
      gradesUpdateInProgress: false,
      autofillInProgress: false,
      autofillEvaluationId: undefined as number | undefined,
      loginAsInProgress: false,
      loginAsUserId: null as number | null,
      loginAsTargetAppUrl: '',
      loginAsModalId: LOGIN_AS_MODAL_ID,
    }
  },

  methods: {
    async updateGrades(): Promise<void> {
      const root = this.$root
      const toastTitle = this.$tc('admin_update_grades_general')
      this.gradesUpdateInProgress = true
      try {
        await new GradeStatsService().updateStats()
        root.$bvToast.toast(root.$tc('success'), {
          variant: 'success',
          title: toastTitle,
        })
      } catch (e) {
        Logger.error('Error during grades update', e)
        root.$bvToast.toast(root.$tc('failure'), {
          variant: 'danger',
          title: toastTitle,
        })
      }
      this.gradesUpdateInProgress = false
    },

    async autofill(): Promise<void> {
      const root = this.$root
      const toastTitle = this.$tc('admin_autofill_grades_general')
      this.autofillInProgress = true
      try {
        await new GradeStatsService().doAutofill(this.autofillEvaluationId!)
        root.$bvToast.toast(root.$tc('success'), {
          variant: 'success',
          title: toastTitle,
        })
      } catch (e) {
        Logger.error('Error during autofill', e)
        root.$bvToast.toast(root.$tc('failure'), {
          variant: 'danger',
          title: toastTitle,
        })
      }
      this.autofillInProgress = false
    },

    async showLoginAsModal() {
      const userId = Assert.notNullOrUndefined(this.loginAsUserId)
      this.loginAsInProgress = true
      try {
        const jwtToken = await new AuthService().loginAs(userId)
        const serializedToken = encodeURIComponent(jwtToken.serialize())
        const rootUrl = location.origin
        const appBaseUrl = process.env.BASE_URL as string

        this.loginAsTargetAppUrl = `${rootUrl}${appBaseUrl}?${LOGIN_AS_TOKEN_PARAMETER_NAME}=${serializedToken}`
        await this.$nextTick() // Allow modal to render before showing
        this.$bvModal.show(LOGIN_AS_MODAL_ID)
      } finally {
        this.loginAsInProgress = false
      }
    },
  },
})
