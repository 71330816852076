










import Vue from 'vue'
import { MetaInfo } from 'vue-meta'
import { User } from '@/services/user/user'
import DashboardAdmin from '@/components/dashboard/DashboardAdmin.vue'
import DashboardStudent from '@/components/dashboard/DashboardStudent.vue'
import BannerNotEnrolled from '@/components/banners/BannerNotEnrolled.vue'
import { AuthStoreKey } from '@/store/modules/auth-store'

export default Vue.extend({
  components: {
    DashboardAdmin,
    DashboardStudent,
    BannerNotEnrolled,
  },

  metaInfo(): MetaInfo {
    return { title: this.$t('dashboard') as string }
  },

  computed: {
    user(): User {
      return this.$store.getters[AuthStoreKey.GETTER_USER]
    },
    currentCourseId(): number | null {
      return this.$store.getters[AuthStoreKey.GETTER_CURRENT_COURSE]?.id ?? null
    },
    isAdmin(): boolean {
      return User.isAdmin(this.user)
    },
  },
})
