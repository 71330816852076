import axios from 'axios'
import { Competence, CompetenceEditable } from './types'
import { Paginated } from '../common/paginated'
import { ServerError } from '../common/server-error'

export class CompetenceService {
  public getCompetences(page: number) {
    return axios
      .get<Paginated<Competence>>(`/api/competences?page=${page}`)
      .then((response) => response.data)
  }

  public async getCompetence(competenceId: number) {
    return await axios
      .get<Competence>(`/api/competences/${competenceId}`)
      .then((response) => response.data)
  }

  public async create(data: CompetenceEditable) {
    return await axios
      .post<Competence>('/api/competences', {
        ...data,
        code: data.name.toLowerCase(),
      })
      .then(
        (response) => response.data,
        (error) => ServerError.from(error)
      )
  }

  public async update(competenceId: number, data: Partial<CompetenceEditable>) {
    return await axios
      .patch<Competence>(`/api/competences/${competenceId}`, {
        ...data,
        code: data.name?.toLowerCase(),
      })
      .then(
        (response) => response.data,
        (error) => ServerError.from(error)
      )
  }

  public async delete(competenceId: number) {
    return await axios.delete(`/api/competences/${competenceId}`).then(
      (_response) => {},
      (error) => ServerError.from(error)
    )
  }
}
