var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":_vm.modalId,"centered":"","title":_vm.$t('user_create_modal_title'),"xl":"","busy":_vm.isLoading},on:{"ok":_vm.handleOk,"show":_vm.resetModal}},[_c('ValidationObserver',{ref:"veeObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
return [_c('b-form',{attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return passes(_vm.doSubmit)}}},[(_vm.serverErrors.length > 0)?_c('b-alert',{attrs:{"variant":"danger","show":""}},_vm._l((_vm.serverErrors),function(error,index){return _c('div',{key:index},[_vm._v(" "+_vm._s(error)+" ")])}),0):_vm._e(),_c('ValidationProvider',{attrs:{"rules":"required|min:5"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"state":errors[0] ? false : null,"placeholder":_vm.$t('name')},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"type":"email","state":errors[0] ? false : null,"placeholder":_vm.$t('email')},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-select',{attrs:{"options":_vm.roleOptions},model:{value:(_vm.role),callback:function ($$v) {_vm.role=$$v},expression:"role"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }