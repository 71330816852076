import { User } from './user'
import { UserRole } from './user-roles'
import { CourseDeserializer } from './course-deserializer'

const courseDeserializer = new CourseDeserializer()

export class UserDeserializer {
  deserialize(json: any): User {
    return new User(
      json.id as number,
      json.name as string,
      json.email as string,
      json.photo_url as string,
      json.role as UserRole,
      courseDeserializer.deserializeMany(json.courses ?? [])
    )
  }
}
