

























































// Note: component derived from https://github.com/devstark-com/vue-circle-slider/

import Vue from 'vue'
import isNil from 'lodash/isNil'

const FULL_CIRCLE_ANGLE = Math.PI * 2 - Number.EPSILON - 0.00001 // correct for 100% value
const DEFAULT_ANGLE = FULL_CIRCLE_ANGLE / 2

export default Vue.extend({
  name: 'SliderCircle',

  props: {
    value: {
      type: Number,
      required: false,
      default: 0,
    },
    text: {
      type: String,
      required: false,
      default: '',
    },
    editable: {
      type: Boolean,
      default: true,
    },
    side: {
      type: Number,
      required: false,
      default: 100,
    },
    stepSize: {
      type: Number,
      required: false,
      default: 1,
    },
    min: {
      type: Number,
      required: false,
      default: 0,
    },
    max: {
      type: Number,
      required: false,
      default: 100,
    },
    circleColor: {
      type: String,
      required: false,
      default: '#F7F9FA',
    },
    progressColor: {
      type: String,
      required: false,
      default: '#282543',
    },
    knobColor: {
      type: String,
      required: false,
      default: '#FFFFFF',
    },
    knobRadius: {
      type: Number,
      required: false,
      default: 6,
    },
    knobRadiusRel: {
      type: Number,
      required: false,
      default: 7,
    },
    circleWidth: {
      type: Number,
      required: false,
      default: 12,
    },
    circleWidthRel: {
      type: Number,
      required: false,
      default: 20,
    },
    progressWidth: {
      type: Number,
      required: false,
      default: 12,
    },
    progressWidthRel: {
      type: Number,
      required: false,
      default: 10,
    },
  },

  data() {
    return {
      innerValue: undefined as number | undefined,
      ignoreNextClick: false,
      radius: 0,
      mousemoveTicks: 0,
      touchPosition: undefined as undefined | TouchPosition,
    }
  },

  computed: {
    angle(): number {
      return this.valueToAngle(this.innerValue)
    },
    cpCenter(): number {
      return this.side / 2
    },
    cpAngle(): number {
      return this.angle + Math.PI / 2
    },
    cpMainCircleStrokeWidth(): number {
      return this.circleWidth || this.side / 2 / this.circleWidthRel
    },
    cpPathDirection(): 0 | 1 {
      return this.cpAngle < (3 / 2) * Math.PI ? 0 : 1
    },
    cpPathX(): number {
      return this.cpCenter + this.radius * Math.cos(this.cpAngle)
    },
    cpPathY(): number {
      return this.cpCenter + this.radius * Math.sin(this.cpAngle)
    },
    cpPathStrokeWidth(): number {
      return this.progressWidth || this.side / 2 / this.progressWidthRel
    },
    cpKnobRadius(): number {
      return this.knobRadius || this.side / 2 / this.knobRadiusRel
    },
    cpPathD(): string {
      const parts = []
      parts.push('M' + this.cpCenter)
      parts.push(this.cpCenter + this.radius)
      parts.push('A')
      parts.push(this.radius)
      parts.push(this.radius)
      parts.push(0)
      parts.push(this.cpPathDirection)
      parts.push(1)
      parts.push(this.cpPathX)
      parts.push(this.cpPathY)
      return parts.join(' ')
    },
    valueRange(): number {
      return this.max - this.min
    },
  },

  watch: {
    value(val) {
      this.updateFromPropValue(val)
    },
  },

  created() {
    this.updateFromPropValue(this.value)
    const maxCurveWidth = Math.max(this.cpMainCircleStrokeWidth, this.cpPathStrokeWidth)
    this.radius = this.side / 2 - Math.max(maxCurveWidth, this.cpKnobRadius * 2) / 2
  },

  mounted() {
    this.touchPosition = new TouchPosition(this.$refs._svg as Element, this.radius, this.radius / 2)
  },

  methods: {
    fitToStep(val: number) {
      return Math.round(val / this.stepSize) * this.stepSize
    },

    handleClick(e: MouseEvent) {
      if (!this.editable) {
        return
      }
      if (this.ignoreNextClick) {
        this.ignoreNextClick = false
        return
      }

      this.touchPosition!.setNewPosition(e)
      if (this.touchPosition!.isTouchWithinSliderRange) {
        this.updateValueFromAngle(this.touchPosition!.sliderAngle)
      }
    },

    handleMouseDown(e: MouseEvent) {
      if (!this.editable) {
        return
      }
      const dragStartAngle = this.touchPosition!.getAngleForXY(e.clientX, e.clientY)
      this.updateValueFromAngle(dragStartAngle)
      e.preventDefault()
      window.addEventListener('mousemove', this.handleWindowMouseMove)
      window.addEventListener('mouseup', this.handleMouseUp)
    },

    handleMouseUp(e: MouseEvent) {
      if (!this.editable) {
        return
      }
      e.preventDefault()
      window.removeEventListener('mousemove', this.handleWindowMouseMove)
      window.removeEventListener('mouseup', this.handleMouseUp)
      this.mousemoveTicks = 0
    },

    handleWindowMouseMove(e: MouseEvent) {
      if (!this.editable) {
        return
      }
      e.preventDefault()
      if (this.mousemoveTicks < 5) {
        this.mousemoveTicks++
        return
      }

      this.ignoreNextClick = true
      this.touchPosition!.setNewPosition(e)
      this.updateFromMouseMove(this.angle)
    },

    handleTouchMove(e: TouchEvent) {
      if (!this.editable) {
        return
      }
      this.$emit('touchmove')
      // Do nothing if two or more fingers used
      if (e.targetTouches.length > 1 || e.changedTouches.length > 1 || e.touches.length > 1) {
        return true
      }

      const lastTouch = e.targetTouches.item(e.targetTouches.length - 1)
      this.touchPosition!.setNewPosition(lastTouch!)

      if (this.touchPosition!.isTouchWithinSliderRange) {
        e.preventDefault()
        this.updateFromMouseMove(this.angle)
      }
    },

    updateFromPropValue(value?: number) {
      this.innerValue = isNil(value) ? undefined : this.fitToStep(value)
    },

    updateFromMouseMove(previousAngle: number) {
      const candidateAngle = this.touchPosition!.sliderAngle
      const areCloseToOrigin =
        this.isAngleCloseToOrigin(previousAngle) && this.isAngleCloseToOrigin(candidateAngle)
      const angleIsWide = Math.abs(candidateAngle - previousAngle) >= Math.PI
      if (angleIsWide && areCloseToOrigin) {
        // Favor min/max depending on which is closest to the previous angle
        const newAngle = this.isAngleCloseToMin(previousAngle) ? 0 : FULL_CIRCLE_ANGLE
        this.updateValueFromAngle(newAngle)
      } else {
        this.updateValueFromAngle(candidateAngle)
      }
    },

    isAngleCloseToOrigin(angle: number) {
      return this.isAngleCloseToMin(angle) || this.isAngleCloseToMax(angle)
    },

    isAngleCloseToMin(angle: number) {
      return angle < Math.PI / 2
    },

    isAngleCloseToMax(angle: number) {
      return angle > Math.PI * 1.5
    },

    updateValueFromAngle(angle: number) {
      const newValue = this.angleToValue(angle)
      this.$emit('input', newValue)
    },

    angleToValue(angle: number) {
      const ratio = angle / FULL_CIRCLE_ANGLE
      const candidateValue = this.min + ratio * this.valueRange
      const candidateValueStep = this.fitToStep(candidateValue)
      return Math.min(Math.max(candidateValueStep, 0), this.max)
    },

    valueToAngle(value?: number): number {
      if (value === undefined) {
        return DEFAULT_ANGLE
      }
      const ratio = (value - this.min) / this.valueRange
      const candidateAngle = FULL_CIRCLE_ANGLE * ratio
      return Math.min(Math.max(candidateAngle, 0), FULL_CIRCLE_ANGLE)
    },
  },
})

const PI_AND_HALF = (Math.PI * 3) / 2
const TWO_PI = Math.PI * 2

type PositionEventType = {
  clientX: number | undefined
  clientY: number | undefined
}

class TouchPosition {
  private containerElement: Element
  private sliderRadius: number
  private sliderTolerance: number
  private clientX: number | undefined
  private clientY: number | undefined
  private center!: number
  private dimensions!: DOMRect

  constructor(containerElement: Element, sliderRadius: number, sliderTolerance: number) {
    this.containerElement = containerElement
    this.sliderRadius = sliderRadius
    this.sliderTolerance = sliderTolerance
    this.setNewPosition({ clientX: undefined, clientY: undefined })
  }

  setNewPosition(e: PositionEventType) {
    const dimensions = this.containerElement.getBoundingClientRect()
    const side = dimensions.width
    this.center = side / 2
    this.clientX = e.clientX
    this.clientY = e.clientY
    this.dimensions = dimensions
  }

  get sliderAngle() {
    return this.getAngleForXY(this.clientX, this.clientY)
  }

  getAngleForXY(clientX: number | undefined, clientY: number | undefined) {
    const relativeX = clientX! - this.dimensions.left
    const relativeY = clientY! - this.dimensions.top
    return (Math.atan2(relativeY - this.center, relativeX - this.center) + PI_AND_HALF) % TWO_PI
  }

  get isTouchWithinSliderRange() {
    const relativeX = this.clientX! - this.dimensions.left
    const relativeY = this.clientY! - this.dimensions.top
    const touchOffset = Math.sqrt(
      Math.pow(Math.abs(relativeX - this.center), 2) +
        Math.pow(Math.abs(relativeY - this.center), 2)
    )
    return Math.abs(touchOffset - this.sliderRadius) <= this.sliderTolerance
  }
}
