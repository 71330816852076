import { BigDecimal } from '@/utils/number-utils'

export class CourseUserStat {
  public readonly courseId: number
  public readonly competenceId: number
  public readonly userId: number
  public readonly autoEvaluationAvg: BigDecimal | null
  public readonly questionnaireAvg: BigDecimal | null
  public readonly peersPerceptionAvg: BigDecimal | null

  constructor(
    courseId: number,
    competenceId: number,
    userId: number,
    autoEvaluationAvg: BigDecimal | null,
    questionnaireAvg: BigDecimal | null,
    peersPerceptionAvg: BigDecimal | null
  ) {
    this.courseId = courseId
    this.competenceId = competenceId
    this.userId = userId
    this.autoEvaluationAvg = autoEvaluationAvg
    this.questionnaireAvg = questionnaireAvg
    this.peersPerceptionAvg = peersPerceptionAvg
  }
}
