
















import Vue from 'vue'
import VueLoadingOverlay from 'vue-loading-overlay'
export default Vue.extend({
  name: 'VLoadingOverlay',

  components: {
    VueLoadingOverlay,
  },

  props: {
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    renderWhileLoading: {
      type: Boolean,
      required: false,
      default: true,
    },
    fullPage: {
      type: Boolean,
      required: false,
      default: false,
    },
    message: {
      type: String,
      required: false,
      default: undefined,
    },
  },

  computed: {
    showContent(): boolean {
      return this.renderWhileLoading || !this.loading
    },
  },
})
