







import Vue from 'vue'
import { MetaInfo } from 'vue-meta'
import { BreadcrumbItem } from '@/components/breadcrumb/breadcrumb-item'
import EvaluationDetail from '@/components/evaluation-detail/EvaluationDetail.vue'
import { RouteName } from '@/router/route-name'

export default Vue.extend({
  components: {
    EvaluationDetail,
  },

  props: {
    evaluationId: {
      type: Number,
      required: true,
    },
    userId: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      breadcrumbItems: getBreadcrumbItems(this.$t.bind(this)),
    }
  },

  metaInfo(): MetaInfo {
    return { title: this.$tc('evaluation_page_title') }
  },
})

function getBreadcrumbItems($t: Function): BreadcrumbItem[] {
  return [
    new BreadcrumbItem($t('evaluations_page_title'), { name: RouteName.EVALUATION_LIST }, false),
    new BreadcrumbItem($t('evaluation_detail_page_title'), null, true),
  ]
}
