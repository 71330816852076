import axios, { AxiosResponse } from 'axios'

export class AxiosUtils {
  public static returnValueIfCancelledOrRethrow<T>(defaultValue: T) {
    return (error: any) => {
      if (axios.isCancel(error)) {
        return defaultValue
      }
      throw error
    }
  }

  public static getSuggestedFilename(response: AxiosResponse) {
    const header = (response.headers['content-disposition'] as string) ?? ''
    const filenameRegex = /filename="(?<filename>.*)"/
    const suggestedFileName = filenameRegex.exec(header)?.groups?.filename ?? null
    return suggestedFileName
  }
}
