














































import Vue from 'vue'
import { MetaInfo } from 'vue-meta'
import moment from 'moment'
import {
  QuestionnaireService,
  AnswerInputType,
  AvailableChoicesSet,
  Questionnaire as QuestionnaireData,
  ExtendedEvaluationQuestion,
  QuestionPreamble,
  AvailableChoice,
  Competence,
} from '@/services/questionnaire/questionnaire-service'
import CompetenceSelection from './CompetenceSelection.vue'
import CompetenceInstructions from './CompetenceInstructions.vue'
import CompetenceQuestionnaire from './CompetenceQuestionnaire.vue'
import { QuestionnaireQuestion, EvaluationAnswer } from './questionnaire-section'
import { RouteName } from '@/router/route-name'

const steps = ['competence-selection', 'competence-instructions', 'questionnaire']

export default Vue.extend({
  components: {
    CompetenceSelection,
    CompetenceInstructions,
    CompetenceQuestionnaire,
  },

  props: {
    evaluationId: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      evaluationName: undefined as string | undefined,
      rawEndDateTime: undefined as string | undefined,
      isLoading: true,
      competences: new Array<Competence>(),
      stepIndex: 0,
      currentCompetence: undefined as Competence | undefined,
      availableChoicesSets: undefined as AvailableChoicesSet[] | undefined,
      questionPreambles: new Array<QuestionPreamble>(),
      questions: new Array<QuestionnaireQuestion>(),
      onLeaveQuestionnaireHook: () => Promise.resolve(true),
    }
  },

  computed: {
    evaluationEndDateTime(): string {
      return this.rawEndDateTime
        ? moment(this.rawEndDateTime).fromNow()
        : this.$tc('general_not_applicable')
    },
    step(): string {
      return steps[this.stepIndex]
    },
    hasUnsavedWork(): boolean {
      return this.questions.some((x) => {
        return x.answer.isDirty
      })
    },
  },

  beforeRouteLeave(_to, _from, next) {
    this.onLeaveQuestionnaireHook().then(() => next())
  },

  beforeMount() {
    window.addEventListener('beforeunload', this.warnUserIfUnsaved)
  },

  beforeDestroy() {
    window.removeEventListener('beforeunload', this.warnUserIfUnsaved)
  },

  mounted() {
    const fetchPromise = new QuestionnaireService().getQuestionnaire(this.evaluationId)
    fetchPromise
      .then((data) => {
        const evaluation = data.evaluation

        this.evaluationName = evaluation.name
        this.rawEndDateTime = evaluation.end_datetime

        this.competences = data.competences
        const $tWrapped = this.$t.bind(this)
        const questions = wrapQuestions(data, $tWrapped)
        questions.sort((el1, el2) => {
          const el1Index = el1.data.index ?? 0
          const el2Index = el2.data.index ?? 0
          return el1Index - el2Index
        })
        this.questions = questions
        this.availableChoicesSets = data.availableChoicesSets
        this.questionPreambles = data.questionPreambles
      })
      .finally(() => this.loadingDone())
  },

  methods: {
    onCompetenceSelected(competence: Competence) {
      this.currentCompetence = competence
      this.stepIndex++
    },

    goToPreviousStep() {
      this.stepIndex--
    },

    goToNextStep() {
      this.stepIndex++
    },

    goBack() {
      const onOk = () => {
        this.stepIndex--
        if (this.stepIndex < 0) {
          this.$router.push({ name: RouteName.EVALUATION_LIST })
        }
      }
      this.onLeaveQuestionnaireHook().then(onOk)
    },

    warnUserIfUnsaved(event: BeforeUnloadEvent) {
      if (this.hasUnsavedWork) {
        this.onLeaveQuestionnaireHook()
        event.preventDefault()
        event.returnValue = ''
      }
    },

    goToQuestionnaireHome() {
      this.stepIndex = 0
    },

    isCompetenceComplete(competence: Competence) {
      const competenceQuestions = this.questions.filter((x) => {
        return x.data.competence_id === competence.id
      })
      return competenceQuestions.every((x) => {
        return x.answer.isCompleted()
      })
    },

    setOnLeaveQuestionnaireHook(promiseFn: () => Promise<boolean>) {
      this.onLeaveQuestionnaireHook = promiseFn
    },

    loadingDone() {
      this.isLoading = false
    },
  },

  metaInfo(): MetaInfo {
    return {
      title: this.$tc('evaluation_questionnaire_page_title'),
    }
  },
})

function wrapQuestions(questionnaireData: QuestionnaireData, $t: Function) {
  return questionnaireData.questions.map((x) => {
    return wrapQuestion(
      x,
      questionnaireData.availableChoicesSets,
      questionnaireData.answerInputTypes,
      $t
    )
  })
}

function wrapQuestion(
  questionData: ExtendedEvaluationQuestion,
  availableChoicesSets: AvailableChoicesSet[],
  answerInputTypes: AnswerInputType[],
  $t: Function
) {
  const answerInputTypeCode = getQuestionAnswerInputType(questionData, answerInputTypes).code
  const answerData = EvaluationAnswer.getAnswerDataFromQuestionData(questionData)
  const questionChoices = getQuestionChoices(questionData, availableChoicesSets)
  const answer = new EvaluationAnswer(answerData, answerInputTypeCode, questionChoices) // TODO: consider that selected choice may no longer be part of available choices
  return new QuestionnaireQuestion(questionData, answerInputTypeCode, answer, $t)
}

function getQuestionChoices(
  questionData: ExtendedEvaluationQuestion,
  availableChoicesSets: AvailableChoicesSet[]
): AvailableChoice[] {
  const choicesSetId = questionData.question.available_choices_set_id
  if (choicesSetId === null) {
    return []
  }
  const choicesSet = availableChoicesSets.find((x) => {
    return x.id === choicesSetId
  })
  return choicesSet!.choices
}

function getQuestionAnswerInputType(
  questionData: ExtendedEvaluationQuestion,
  answerInputTypes: AnswerInputType[]
): AnswerInputType {
  return answerInputTypes.find((x) => {
    return x.id === questionData.question.answer_input_type_id
  })!
}
