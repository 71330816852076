import axios from 'axios'
import store from '@/store'
import router from '@/router'
import sweetAlert from 'sweetalert2'
import i18n from './i18n'
import { JwtToken } from '@/services/common/jwt-token'
import { AuthStoreKey } from '@/store/modules/auth-store'
import { OngoingRequestInterceptor } from './ongoing-request-interceptor'

axios.interceptors.request.use((request) => {
  const token: JwtToken = store.getters[AuthStoreKey.GETTER_TOKEN]
  if (token) {
    request.headers.common['Authorization'] = `Bearer ${token.accessToken}`
  }
  return request
})

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    const status = error.response?.status

    if (status >= 500) {
      sweetAlert.fire({
        type: 'error',
        title: i18n.tc('error_alert_title'),
        text: i18n.tc('error_alert_text'),
        reverseButtons: true,
        confirmButtonText: i18n.tc('ok'),
        cancelButtonText: i18n.tc('cancel'),
      })
    }

    if (status === 401 && store.getters[AuthStoreKey.GETTER_IS_LOGGED_IN]) {
      sweetAlert
        .fire({
          type: 'warning',
          title: i18n.tc('token_expired_alert_title'),
          text: i18n.tc('token_expired_alert_text'),
          reverseButtons: true,
          confirmButtonText: i18n.tc('ok'),
          cancelButtonText: i18n.tc('cancel'),
        })
        .then(() => {
          store.commit(AuthStoreKey.MUT_LOGOUT)
          router.push({ name: 'login' })
        })
    }

    return Promise.reject(error)
  }
)

new OngoingRequestInterceptor().register()
