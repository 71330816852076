import ldRound from 'lodash/round'

export type BigDecimal = string

export class NumberUtils {
  static bigDecimalToNumber(bigDecimal: BigDecimal) {
    return Number.parseFloat(bigDecimal)
  }

  static round(value: number, precision = 0) {
    return ldRound(value, precision)
  }
}
