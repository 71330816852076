















































import Vue from 'vue'
import Form from 'vform'
import { MetaInfo } from 'vue-meta'

export default Vue.extend({
  metaInfo(): MetaInfo {
    return { title: this.$tc('settings') }
  },

  data() {
    return {
      form: new Form({
        password: '',
        password_confirmation: '',
      }),
    }
  },

  methods: {
    async update() {
      await this.form.patch('/api/settings/password')

      this.form.reset()
    },
  },
})
