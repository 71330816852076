export class Course {
  public readonly id: number
  public readonly name: string
  public readonly startDatetime: string

  constructor(id: number, name: string, startDatetime: string) {
    this.id = id
    this.name = name
    this.startDatetime = startDatetime
  }
}
