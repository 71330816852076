



































































import Vue from 'vue'
import { Grades } from '@/models/grades/grades'
import { Assert } from '@/utils/assert'
import { GradeStatsService } from '@/services/grade-stats/grade-stats-service'
import { ReportCardService } from '@/services/report-card/report-card-service'
import { ReportCardCustomMetricEntry, Metric } from '@/services/report-card/types'
import { Competence } from '@/models/grades/competence'
import { EvaluationDetail } from '@/services/evaluation/types'
import { EvaluationService } from '@/services/evaluation/evaluation-service'
import { LabelUtils } from '@/utils/label-utils'

class CompetenceEntry {
  public competence: Competence
  public headers: CompetenceEntryCol[]
  public rows: CompetenceEntryRow[]
  public customMetricsRows: CompetenceEntryRow[]
  public commentsRow = new Array<Array<CommentInfo>>()

  constructor(
    competence: Competence,
    headers: CompetenceEntryCol[],
    rows: CompetenceEntryRow[],
    customMetricsRows: CompetenceEntryRow[],
    commentsRow: Array<Array<CommentInfo>>
  ) {
    this.competence = competence
    this.headers = headers
    this.rows = rows
    this.customMetricsRows = customMetricsRows
    this.commentsRow = commentsRow
  }
}

class CompetenceEntryRow {
  public measurementName: string
  public columns: CompetenceEntryCol[]

  constructor(measurementName: string, columns: CompetenceEntryCol[]) {
    this.measurementName = measurementName
    this.columns = columns
  }
}

class CompetenceEntryCol {
  public text: string | null

  public constructor(text: string | null) {
    this.text = text
  }
}

class CommentInfo {
  public author: string
  public authorAcronym: string | null
  public comment: string

  constructor(author: string, authorAcronym: string | null, comment: string) {
    this.author = author
    this.authorAcronym = authorAcronym
    this.comment = comment
  }
}

export default Vue.extend({
  props: {
    courseId: {
      type: Number,
      required: true,
    },
    userId: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      busy: true,
      competenceEntries: new Array<CompetenceEntry>(),
      publicPath: process.env.BASE_URL as string,
    }
  },

  async created() {
    this.busy = true
    try {
      const customMetricsPromise = new ReportCardService().getCustomMetrics(
        this.courseId,
        this.userId
      ).promise
      const maybeGrades = await new GradeStatsService().getAllStats(this.courseId, this.userId)
        .promise
      const grades = Assert.notNullOrUndefined(maybeGrades)
      const evaluationDetailsPromises = grades.getEvaluations().map(async (ev) => {
        return new EvaluationService().getDetail(ev.id, this.userId).promise
      })
      const evaluationDetailPromise = await Promise.all(evaluationDetailsPromises)
      const evaluationDetails = evaluationDetailPromise.map((x) => Assert.notNullOrUndefined(x))
      const customMetrics = await customMetricsPromise
      this.updateReportData(grades, evaluationDetails, customMetrics)
    } finally {
      this.busy = false
    }
  },

  methods: {
    updateReportData(
      grades: Grades,
      evaluationDetails: EvaluationDetail[],
      customMetricEntries: ReportCardCustomMetricEntry[]
    ) {
      const anonymousName = this.$tc('user_anonymous_name')
      const headers = grades.getEvaluations().map((ev) => new CompetenceEntryCol(ev.name))
      const evaluations = grades.getEvaluations()
      const evaluationDetailById = evaluationDetails.reduce((map, evaluationDetail) => {
        map.set(evaluationDetail.evaluation.id, evaluationDetail)
        return map
      }, new Map<number, EvaluationDetail>())

      const competenceEntries = grades.getCompetences().map((competence) => {
        const userColumnData = evaluations.map((ev) =>
          grades.getEvaluationStatsForUser(ev.id, competence.id)
        )
        const classColumnData = evaluations.map((ev) =>
          grades.getEvaluationStatsForClass(ev.id, competence.id)
        )

        const questionnaireScoreColumns = userColumnData.map(
          (x) => new CompetenceEntryCol(LabelUtils.bigDecimalToText(x?.questionnaireAvg))
        )
        const questionnaireScoreRow = new CompetenceEntryRow(
          this.$tc('report_card_measurement_questionnaire_score'),
          questionnaireScoreColumns
        )

        const questionnaireAvgColumns = classColumnData.map(
          (x) => new CompetenceEntryCol(LabelUtils.bigDecimalToText(x?.questionnaireAvg))
        )
        const questionnaireAvgRow = new CompetenceEntryRow(
          this.$tc('report_card_measurement_questionnaire_avg'),
          questionnaireAvgColumns
        )

        const autoEvalScoreColumns = userColumnData.map(
          (x) => new CompetenceEntryCol(LabelUtils.bigDecimalToText(x?.autoEvaluationAvg))
        )
        const autoEvalScoreRow = new CompetenceEntryRow(
          this.$tc('report_card_measurement_auto_eval'),
          autoEvalScoreColumns
        )

        const peersPerceptionColumns = userColumnData.map(
          (x) => new CompetenceEntryCol(LabelUtils.bigDecimalToText(x?.peersPerceptionAvg))
        )
        const peersPerceptionRow = new CompetenceEntryRow(
          this.$tc('report_card_measurement_peers_perception'),
          peersPerceptionColumns
        )

        const rows = [
          questionnaireScoreRow,
          questionnaireAvgRow,
          autoEvalScoreRow,
          peersPerceptionRow,
        ]

        const competenceMetricEntries = customMetricEntries.filter((x) => {
          return x.competenceId === competence.id
        })
        const customMetricsById = competenceMetricEntries.reduce((accumulator, entry) => {
          accumulator.set(entry.metricId, {
            id: entry.metricId,
            label: entry.metricLabel,
          })
          return accumulator
        }, new Map<number, Metric>())
        const customMetricRows = new Array<CompetenceEntryRow>()
        const customMetrics = [...customMetricsById.values()].sort((a, b) => a.id - b.id) // TODO: Use index field to order, instead of DB order
        for (const customMetric of customMetrics) {
          const columns = evaluations.map((ev) => {
            const maybeValue = competenceMetricEntries.find(
              (entry) => entry.evaluationId === ev.id && entry.metricId === customMetric.id
            )
            const value = maybeValue === undefined ? null : maybeValue.value
            return new CompetenceEntryCol(value)
          })
          customMetricRows.push(new CompetenceEntryRow(customMetric.label, columns))
        }

        const commentsRow = evaluations.map((ev) => {
          const maybeDetail = evaluationDetailById.get(ev.id)
          if (maybeDetail !== undefined) {
            const detail = maybeDetail
            const competenceComments = detail.peerComments.filter(
              (pc) => pc.competenceId === competence.id
            )
            return competenceComments.map((peerComment) => {
              const respondent = Assert.notNullOrUndefined(
                detail.respondents.find((x) => x.id === peerComment.respondentId)
              )
              const acronym = LabelUtils.toNameAcronym(respondent.name)
              return new CommentInfo(respondent.name || anonymousName, acronym, peerComment.comment)
            })
          }
          return []
        })
        const entry = new CompetenceEntry(competence, headers, rows, customMetricRows, commentsRow)
        return entry
      })
      this.competenceEntries = competenceEntries
    },

    showCommentModal(commentInfo: CommentInfo) {
      this.$bvModal.msgBoxOk(commentInfo.comment, {
        title: commentInfo.author,
        size: 'lg',
      })
    },
  },
})
