import { AxiosError, AxiosResponse } from 'axios'
import { Logger } from '@/modules/logger'

type AxiosLaravelErrorResponse = AxiosResponse<LaravelErrorData>

type LaravelErrorData = {
  message: string
  errors?: {
    [key: string]: string[]
  }
}

type ValidationError = {
  fieldName: string
  message: string
}
export class ServerError extends Error {
  public readonly generalError = 'Unknown error'
  public readonly validationErrors = new Array<ValidationError>()

  private readonly axiosError: AxiosError

  constructor(axiosError: AxiosError) {
    super(axiosError.message)
    this.axiosError = axiosError
    try {
      this.generalError = axiosError.response?.data?.message ?? axiosError.message
      this.validationErrors = this.parseValidationErrors(axiosError.response)
    } catch (ex) {
      Logger.error('Error while parsing ValidationError. Ignoring')
      Logger.error(ex)
    }
  }

  get response() {
    return this.axiosError.response
  }

  public errorsAsSimpleFormat() {
    return this.validationErrors.length === 0
      ? [this.generalError]
      : this.validationErrors.map((x) => x.message)
  }

  rethrow(): never {
    throw this.axiosError
  }

  static from(axiosError: AxiosError) {
    return new ServerError(axiosError)
  }

  private parseValidationErrors(response: AxiosLaravelErrorResponse | undefined) {
    const responseData = response?.data
    const validationErrors = new Array<ValidationError>()
    if (typeof responseData === 'object') {
      const errors = responseData.errors
      if (errors) {
        for (const fieldName of Object.keys(errors)) {
          for (const message of errors[fieldName]) {
            validationErrors.push({
              fieldName: fieldName,
              message: message,
            })
          }
        }
      }
    }
    return validationErrors
  }
}
