














import Vue, { PropType } from 'vue'
import { Competence } from '@/services/common/models/competence'

export default Vue.extend({
  name: 'VCompetenceCard',

  props: {
    competence: {
      type: Object as PropType<Competence>,
      required: true,
    },
  },

  data() {
    return {
      publicPath: process.env.BASE_URL as string,
    }
  },
})
