








































import { CompetenceService } from '@/services/competence/competence-service'
import { Competence } from '@/services/competence/types'
import VCompetenceCard from '@/components/VCompetenceCard.vue'
import { Logger } from '@/modules/logger'
import Vue from 'vue'
import { MetaInfo } from 'vue-meta'

export default Vue.extend({
  components: {
    VCompetenceCard,
  },

  data() {
    return {
      competences: new Array<Competence>(),
      isBusy: false,
      totalRows: -1,
      currentPage: 1,
      perPage: 0,
    }
  },

  metaInfo(): MetaInfo {
    return { title: this.$tc('competences_section_title') }
  },

  created() {
    this.onPageChange()
  },

  methods: {
    async onPageChange() {
      this.isBusy = true
      try {
        const pagedCompetences = await new CompetenceService().getCompetences(this.currentPage)
        this.totalRows = pagedCompetences.total
        this.currentPage = pagedCompetences.current_page
        this.perPage = pagedCompetences.per_page
        this.competences = pagedCompetences.data
      } catch (error) {
        Logger.error(error)
      } finally {
        this.isBusy = false
      }
    },
  },
})
