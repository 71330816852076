















































































import Vue, { PropType } from 'vue'
import { MetaInfo } from 'vue-meta'
import ldDebounce from 'lodash/debounce'
import { BreadcrumbItem } from '@/components/breadcrumb/breadcrumb-item'
import RichTextEditor from '@/components/RichTextEditor.vue'
import VCompetenceCard from '@/components/VCompetenceCard.vue'
import { RouteName } from '@/router/route-name'
import { CompetenceService } from '@/services/competence/competence-service'
import { Logger } from '@/modules/logger'
import { Competence, CompetenceEditable } from '@/services/competence/types'
import { ServerError } from '@/services/common/server-error'
import { ValidationObserverInstance } from '@/utils/misc-types'

enum Mode {
  CREATE,
  UPDATE,
}

const competenceService = new CompetenceService()

export default Vue.extend({
  components: {
    RichTextEditor,
    VCompetenceCard,
  },

  props: {
    initialCompetenceId: {
      type: Number as PropType<number | null>,
      required: false,
      default: null,
    },
  },

  data() {
    return {
      competenceId: this.initialCompetenceId,
      name: '',
      logoUrl: '',
      descriptionHtml: '',
      breadcrumbItems: getBreadcrumbItems(this.$t.bind(this)),
      previewVisible: true,
      isSettingUp: true,
      isSaving: false,
      serverErrors: new Array<string>(),
      debouncedUpdateDescriptionHtml: () => {},
    }
  },

  computed: {
    competence: {
      get(): CompetenceEditable {
        return {
          name: this.name,
          logo_url: this.logoUrl,
          description_html: this.descriptionHtml,
        }
      },
      set(competence: Competence) {
        this.competenceId = competence.id
        this.name = competence.name
        this.logoUrl = competence.logo_url
        this.descriptionHtml = competence.description_html
      },
    },
    veeObserver(): ValidationObserverInstance {
      return this.$refs.veeObserver as ValidationObserverInstance
    },
    mode(): Mode {
      return this.competenceId === null ? Mode.CREATE : Mode.UPDATE
    },
    isUpdateMode(): boolean {
      return this.mode === Mode.UPDATE
    },
    togglePreviewBtnLabelKey(): string {
      return this.previewVisible ? 'general_hide_preview' : 'general_show_preview'
    },
    saveBtnLabelKey(): string {
      return this.mode === Mode.CREATE ? 'general_add' : 'general_save'
    },
  },

  metaInfo(): MetaInfo {
    return {
      title: this.$tc('competence_detail_page_title'),
    }
  },

  created() {
    this.debouncedUpdateDescriptionHtml = ldDebounce(this.updateDescriptionHtml, 500, {
      maxWait: 1500,
    })
    this.fetchCompetence()
  },

  methods: {
    async fetchCompetence() {
      this.isSettingUp = true
      if (this.mode === Mode.UPDATE) {
        try {
          const competence = await competenceService.getCompetence(this.competenceId!)
          this.competence = competence
        } catch (error) {
          Logger.error(error)
        }
      }
      this.isSettingUp = false
    },

    async tryDelete() {
      const confirms = await this.$bvModal.msgBoxConfirm(this.$tc('general_confirm_message'), {
        okVariant: 'danger',
      })
      if (confirms) {
        this.isSaving = true
        this.serverErrors = []
        try {
          const competenceOrError = await competenceService.delete(this.competenceId!)
          if (competenceOrError instanceof ServerError) {
            const error = competenceOrError
            this.serverErrors = error.errorsAsSimpleFormat()
          } else {
            this.leave()
          }
        } finally {
          this.isSaving = false
        }
      }
    },

    async trySave() {
      const formIsValid = await this.isFormValid()
      if (formIsValid) {
        await this.doSubmit()
      }
    },

    async tryCancel() {
      if (this.isFormDirty()) {
        const confirms = await this.$bvModal.msgBoxConfirm(this.$tc('general_unsaved_confirmation'))
        if (!confirms) {
          return
        }
      }
      this.leave()
    },

    async doSubmit() {
      this.isSaving = true
      this.serverErrors = []
      try {
        const competenceOrError = this.competenceId
          ? await competenceService.update(this.competenceId, this.competence)
          : await competenceService.create(this.competence)
        if (competenceOrError instanceof ServerError) {
          const error = competenceOrError
          this.serverErrors = error.errorsAsSimpleFormat()
        } else {
          this.competence = competenceOrError
          this.leave()
        }
      } finally {
        this.isSaving = false
      }
    },

    async isFormValid() {
      return this.veeObserver.validate()
    },

    isFormDirty() {
      return this.veeObserver.flags.dirty
    },

    onHtmlEditorBlur() {
      this.descriptionHtml = this.getEditorHtml() // TODO save and properly update
    },

    onHtmlEditorUpdate() {
      this.debouncedUpdateDescriptionHtml()
    },

    updateDescriptionHtml() {
      this.descriptionHtml = this.getEditorHtml()
    },

    getEditorHtml() {
      return (this.$refs.htmlEditor as any).getHtml()
    },

    leave() {
      this.$router.push({ name: RouteName.COMPETENCE_LIST })
    },
  },
})

function getBreadcrumbItems($t: Function): BreadcrumbItem[] {
  return [
    new BreadcrumbItem(
      $t('competence_list_page_title'),
      { name: RouteName.COMPETENCE_LIST },
      false
    ),
    new BreadcrumbItem($t('competence_detail_page_title'), null, true),
  ]
}
