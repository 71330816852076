import ldIsNil from 'lodash/isNil'
import { NumberUtils } from '@/utils/number-utils'
import { Grades } from '@/models/grades/grades'
import { defaultChartColors } from './chart-colors'
import { ChartData } from 'chart.js'

const defaultDatasetOptions = {
  fill: false,
  lineTension: 0,
  borderWidth: 2,
}

export class GradesToChartDataConverter {
  convertToUserProgression(grades: Grades): ChartData {
    const evaluations = grades.getEvaluationsWithQuestions()
    const labels = evaluations.map((x) => x.name)
    const datasets = []
    const competences = grades.getCompetences()
    for (const [index, competence] of competences.entries()) {
      const data = evaluations.map((evaluation) =>
        getUserEvaluationResult(grades, evaluation.id, competence.id)
      )
      const colorIndex = index % defaultChartColors.length
      const dataset = {
        label: competence.name,
        data: data,
        borderColor: defaultChartColors[colorIndex].rgbaString,
        ...defaultDatasetOptions,
      }
      datasets.push(dataset)
    }
    return {
      labels: labels,
      datasets: datasets,
    }
  }

  convertToCourseProgression(grades: Grades): ChartData {
    const evaluations = grades.getEvaluationsWithQuestions()
    const labels = evaluations.map((x) => x.name)
    const datasets = []
    const competences = grades.getCompetences()
    for (const [index, competence] of competences.entries()) {
      const data = evaluations.map((evaluation) =>
        getClassEvaluationResult(grades, evaluation.id, competence.id)
      )
      const colorIndex = index % defaultChartColors.length
      const dataset = {
        label: competence.name,
        data: data,
        borderColor: defaultChartColors[colorIndex].rgbaString,
        ...defaultDatasetOptions,
      }
      datasets.push(dataset)
    }
    return {
      labels: labels,
      datasets: datasets,
    }
  }
}

function getUserEvaluationResult(
  grades: Grades,
  evaluationId: number,
  competenceId: number
): number | undefined {
  const userStat = grades.getEvaluationStatsForUser(evaluationId, competenceId)
  const rawValue = userStat?.questionnaireAvg
  return ldIsNil(rawValue) ? undefined : NumberUtils.bigDecimalToNumber(rawValue)
}

function getClassEvaluationResult(
  grades: Grades,
  evaluationId: number,
  competenceId: number
): number | undefined {
  const userStat = grades.getEvaluationStatsForClass(evaluationId, competenceId)
  const rawValue = userStat?.questionnaireAvg
  return ldIsNil(rawValue) ? undefined : NumberUtils.bigDecimalToNumber(rawValue)
}
