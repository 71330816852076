












import Vue from 'vue'

export default Vue.extend({
  name: 'VCard',

  props: {
    title: { type: String, default: null },
  },
})
