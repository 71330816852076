




















import Vue, { PropType } from 'vue'
import QuestionnaireRadioGroupItem from './QuestionnaireRadioGroupItem.vue'

type Choice = {
  id: number
  label: string
}

export default Vue.extend({
  components: {
    QuestionnaireRadioGroupItem,
  },

  model: {
    prop: 'value',
    event: 'input',
  },

  props: {
    value: {
      type: (null as unknown) as PropType<any>,
      default: undefined,
    },
    availableValues: {
      type: Array as PropType<Choice[]>,
      required: true,
    },
    validator: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      innerValue: undefined,
    }
  },

  watch: {
    value(newVal) {
      this.innerValue = newVal
    },
  },

  created() {
    this.innerValue = this.value
  },
})
