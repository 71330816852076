
























































import Vue from 'vue'
import { CompetenceSingleRating } from '@/components/dashboard/competence-single-rating'
import ProgressCircleDouble from '@/components/ProgressCircleDouble.vue'
import LineChart from '@/components/dashboard/chart/LineChart.vue'
import { GradesToChartDataConverter } from '@/components/dashboard/chart/grades-to-chart-data-converter'
import PlaceholderNoData from './PlaceholderNoData.vue'
import { Grades } from '@/models/grades/grades'
import { User } from '@/services/user/user'
import { AuthStoreKey } from '@/store/modules/auth-store'
import { Assert } from '@/utils/assert'
import { GradeStatsService } from '@/services/grade-stats/grade-stats-service'

const chartDataConverter = new GradesToChartDataConverter()

export default Vue.extend({
  components: {
    ProgressCircleDouble,
    LineChart,
    PlaceholderNoData,
  },

  props: {
    courseId: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      autoEvaluationRatings: [] as CompetenceSingleRating[],
      questionnaireRatings: [] as CompetenceSingleRating[],
      isLoading: true,
      progressionData: undefined as any,
    }
  },

  computed: {
    user(): User {
      return this.$store.getters[AuthStoreKey.GETTER_USER]
    },
  },

  created() {
    const gradesPromise = new GradeStatsService().getAllStats(this.courseId, this.user.id).promise
    gradesPromise
      .then((maybeGrades) => {
        const grades = Assert.notNullOrUndefined(maybeGrades)
        this.updateRatings(grades)
        this.updateChartData(grades)
      })
      .finally(() => {
        this.isLoading = false
      })
  },

  methods: {
    updateChartData(grades: Grades) {
      this.progressionData = chartDataConverter.convertToCourseProgression(grades)
    },
    updateRatings(grades: Grades) {
      const competences = grades.getCompetences()
      const autoEvaluationRatings = [] as CompetenceSingleRating[]
      const questionnaireRatings = [] as CompetenceSingleRating[]
      for (const competence of competences) {
        const courseStat = grades.courseStats.find((x) => x.competenceId === competence.id)
        const questionnaireRating = new CompetenceSingleRating(competence.name)
        questionnaireRating.rating = courseStat?.questionnaireAvg

        const autoEvaluationRating = new CompetenceSingleRating(competence.name)
        autoEvaluationRating.rating = courseStat?.autoEvaluationAvg

        autoEvaluationRatings.push(autoEvaluationRating)
        questionnaireRatings.push(questionnaireRating)
      }
      this.autoEvaluationRatings = autoEvaluationRatings
      this.questionnaireRatings = questionnaireRatings
    },
  },
})
