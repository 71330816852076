import axios from 'axios'
import store from '@/store'
import { LoadingStoreKey } from '@/store/modules/loading-store'

export class OngoingRequestInterceptor {
  private ongoingRequestCount = 0

  public register() {
    axios.interceptors.request.use(
      (request) => {
        this.onRequestStarted()
        return request
      },
      (error) => {
        this.onRequestCompleted()
        return Promise.reject(error)
      }
    )

    axios.interceptors.response.use(
      (response) => {
        this.onRequestCompleted()
        return response
      },
      (error) => {
        this.onRequestCompleted()
        return Promise.reject(error)
      }
    )
  }

  private onRequestStarted() {
    this.ongoingRequestCount++
    this.updateAppState()
  }

  private onRequestCompleted() {
    this.ongoingRequestCount--
    this.updateAppState()
  }

  private updateAppState() {
    store.commit(LoadingStoreKey.SET_REQUEST_PENDING, this.isLoading())
  }

  private isLoading() {
    return this.ongoingRequestCount > 0
  }
}
