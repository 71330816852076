import { BigDecimal } from '@/utils/number-utils'

export class CompetenceSingleRating {
  public rating?: BigDecimal
  public competenceName: string

  constructor(competenceName: string) {
    this.competenceName = competenceName
  }
}
