import axios from 'axios'
import { Questionnaire, EvaluationAnswer } from './types'

export * from './types'

export class QuestionnaireService {
  public async getQuestionnaire(evaluationId: number): Promise<Questionnaire> {
    return axios
      .get(`/api/questionnaire-helper/questionnaire/${evaluationId}`)
      .then((response) => response.data)
  }

  public async saveAnswer(answer: Partial<EvaluationAnswer>): Promise<EvaluationAnswer> {
    return axios
      .post('/api/questionnaire-helper/save-answer', answer)
      .then((response) => response.data)
  }
}
