import store from '@/store'
import { Middleware, MiddlewareOutcome } from './types'
import { JwtToken } from '@/services/common/jwt-token'
import { AuthStoreKey } from '@/store/modules/auth-store'
import { Route } from 'vue-router'

const ONE_HOUR = 60 * 60 * 1000

export class CheckSessionAboutToExpireMiddleware implements Middleware {
  async execute(_from: Route, _to: Route) {
    const token: JwtToken = store.getters[AuthStoreKey.GETTER_TOKEN]
    const tokenExpiryTime = token?.expiresAt?.getTime() ?? Infinity // Non-session never expires
    const redirectAfterTime = tokenExpiryTime - ONE_HOUR
    const aboutToExpire = new Date().getTime() > redirectAfterTime
    if (aboutToExpire) {
      return MiddlewareOutcome.stop({ name: 'login' })
    }
    return MiddlewareOutcome.continue()
  }
}
