






















import Vue, { PropType } from 'vue'

export default Vue.extend({
  model: {
    prop: 'value',
    event: 'input',
  },

  props: {
    circleLabel: {
      type: String,
      required: true,
    },
    textLabel: {
      type: String,
      required: true,
    },
    value: {
      // Selected value
      type: (null as unknown) as PropType<any>,
      default: undefined,
    },
    associatedValue: {
      type: (null as unknown) as PropType<any>,
      required: true,
    },
  },

  computed: {
    isSelected(): boolean {
      return this.associatedValue === this.value
    },
  },

  methods: {
    select(): void {
      const newValue = this.associatedValue
      if (newValue !== this.value) {
        this.$emit('input', newValue)
      }
    },
  },
})
