import Vue from 'vue'
import { VueConstructor } from 'vue/types/umd'
import Breadcrumb from './breadcrumb/Breadcrumb.vue'
import Question from './Question.vue'
import SliderCircle from './SliderCircle.vue'
import { HasError, AlertError, AlertSuccess } from 'vform'
import Loading from 'vue-loading-overlay'
import VButton from './VButton.vue'
import VBusySpinner from './VBusySpinner.vue'
import VCard from './VCard.vue'
import VLoadingOverlay from './VLoadingOverlay.vue'
import VLayoutRouterContent from './VLayoutRouterContent.vue'
import 'vue-loading-overlay/dist/vue-loading.css'
import { Assert } from '@/utils/assert'

const namedComponents = [HasError, AlertError, AlertSuccess, Loading]
namedComponents.forEach((component) => {
  Vue.component(component.name, component)
})

const components = [
  Breadcrumb,
  Question,
  SliderCircle,
  VButton,
  VBusySpinner,
  VCard,
  VLayoutRouterContent,
  VLoadingOverlay,
]
components.forEach((component) => {
  Vue.component(getExtendedVueConstructorName(component), component)
})

Vue.use(Loading)
Vue.component('Loading', Loading)

function getExtendedVueConstructorName(vueConstructor: VueConstructor): string {
  return Assert.isDefined(vueConstructor.options!.name)
}
