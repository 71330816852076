
































import Vue from 'vue'
import { LabelUtils } from '@/utils/label-utils'
import SliderCircle from './SliderCircle.vue'
import { BigDecimal, NumberUtils } from '@/utils/number-utils'

export default Vue.extend({
  name: 'ProgressCircleDouble',

  components: {
    SliderCircle,
  },

  props: {
    label: {
      type: String,
      required: true,
    },
    ratingPrimary: {
      type: String,
      required: false,
      default: undefined,
    },
    ratingSecondary: {
      type: String,
      required: false,
      default: undefined,
    },
    forceShowSecondaryRating: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      innerBoxWidth: 130,
      innerLineWidth: 8,
      outerBoxWidth: 150,
      outerLineWidth: 12,
    }
  },

  computed: {
    ratingPrimaryLabel(): string {
      return this.formatRating(this.ratingPrimary)
    },
    ratingPrimaryNumber(): number {
      return NumberUtils.bigDecimalToNumber(this.ratingPrimary)
    },
    showSecondaryRating(): boolean {
      return this.forceShowSecondaryRating || Boolean(this.ratingSecondary)
    },
    ratingSecondaryLabel(): string {
      return this.formatRating(this.ratingSecondary)
    },
    ratingSecondaryNumber(): number {
      return NumberUtils.bigDecimalToNumber(this.ratingSecondary)
    },
  },

  methods: {
    formatRating(value?: BigDecimal): string {
      return LabelUtils.bigDecimalToText(value)
    },
  },
})
