













import Vue from 'vue'

export default Vue.extend({
  name: 'VButton',

  props: {
    type: {
      type: String,
      default: 'primary',
    },

    loading: {
      type: Boolean,
      default: false,
    },
  },
})
