



















































































































import Vue from 'vue'
import moment from 'moment'
import ProgressCircleDouble from '@/components/ProgressCircleDouble.vue'
import { EvaluationService } from '@/services/evaluation/evaluation-service'
import { EvaluationDetail } from '@/services/evaluation/types'
import { UserRoleUtil } from '@/services/user/user-roles'
import { PeerComment } from './peer-comment'
import EvaluatorCommentsModal from './EvaluationDetailCommentsModal.vue'
import { CompetenceDoubleRating } from '@/components/dashboard/competence-double-rating'
import { GradeStatsService } from '@/services/grade-stats/grade-stats-service'
import { AuthStoreKey } from '@/store/modules/auth-store'
import { Assert } from '@/utils/assert'
import { Grades } from '@/models/grades/grades'

const PEER_COMMENTS_MODAL_ID = 'peerCommentsModalId'

export default Vue.extend({
  components: {
    EvaluatorCommentsModal,
    ProgressCircleDouble,
  },

  props: {
    evaluationId: {
      type: Number,
      required: true,
    },
    userId: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      evaluationDetail: null as EvaluationDetail | null,
      autoEvaluationRatings: [] as CompetenceDoubleRating[],
      questionnaireRatings: [] as CompetenceDoubleRating[],
      cancelEvaluationDetailFetchFn: () => {},
      cancelGradesFetchFn: () => {},
      isLoading: false,
      commentTableFields: getEvaluatorTableFields(this.$t.bind(this)),
      peerComments: Array<PeerComment>(),
      peerCommentsModalId: PEER_COMMENTS_MODAL_ID,
      currentEvaluatorComment: null as PeerComment | null,
    }
  },

  computed: {
    evaluationName(): string | undefined {
      return this.evaluationDetail?.evaluation?.name
    },
    startDatetime(): string | null {
      const startRaw = this.evaluationDetail?.evaluation?.start_datetime ?? null
      return startRaw === null ? null : moment(startRaw).format()
    },
    endDatetime(): string | null {
      const endRaw = this.evaluationDetail?.evaluation?.end_datetime ?? null
      return endRaw === null ? null : moment(endRaw).format()
    },
  },

  watch: {
    $route() {
      this.updateData()
    },
  },

  created() {
    this.updateData()
  },

  destroyed() {
    this.cancelGradesFetchFn()
    this.cancelEvaluationDetailFetchFn()
  },

  methods: {
    updateData() {
      this.loadingStarted()
      const gradeStatsPromise = this.updateGradeStats()
      const evaluationDetailPromise = this.updateEvaluationDetail()
      Promise.all([gradeStatsPromise, evaluationDetailPromise]).then(
        () => this.loadingDone(),
        () => this.loadingDone()
      )
    },

    async updateGradeStats() {
      const courseId = Assert.notNullOrUndefined(
        this.$store.getters[AuthStoreKey.GETTER_CURRENT_COURSE]?.id
      )
      const cancellablePromise = new GradeStatsService().getAllStats(courseId, this.userId)
      this.cancelGradesFetchFn = cancellablePromise.cancel

      const grades = await cancellablePromise.promise
      if (grades !== null /* cancelled */) {
        this.updateRatings(grades, this.evaluationId)
      }
    },

    async updateEvaluationDetail() {
      const cancellablePromise = new EvaluationService().getDetail(this.evaluationId, this.userId)
      this.cancelEvaluationDetailFetchFn = cancellablePromise.cancel

      const evaluationDetail = await cancellablePromise.promise
      if (evaluationDetail !== null /* cancelled */) {
        this.evaluationDetail = evaluationDetail
        this.peerComments = evaluationDetail.respondents.map((respondent) => {
          const comments = evaluationDetail.peerComments.filter(
            (peerComment) => peerComment.respondentId === respondent.id
          )
          return new PeerComment(
            respondent,
            UserRoleUtil.codeToTranslationKey(respondent.role),
            comments
          )
        })
      }
    },

    updateRatings(grades: Grades, evaluationId: number) {
      const competences = grades.getCompetences()
      const autoEvaluationRatings = [] as CompetenceDoubleRating[]
      const questionnaireRatings = [] as CompetenceDoubleRating[]
      for (const competence of competences) {
        const evaluationStat = grades.evaluationStats.find(
          (x) => x.competenceId === competence.id && x.evaluationId === evaluationId
        )
        const evaluationUserStat = grades.evaluationUserStats.find(
          (x) => x.competenceId === competence.id && x.evaluationId === evaluationId
        )
        const questionnaireRating = new CompetenceDoubleRating(
          competence.name,
          evaluationUserStat?.questionnaireAvg ?? null,
          evaluationStat?.questionnaireAvg ?? null
        )
        const autoEvaluationRating = new CompetenceDoubleRating(
          competence.name,
          evaluationUserStat?.autoEvaluationAvg ?? null,
          evaluationUserStat?.peersPerceptionAvg ?? null
        )
        autoEvaluationRatings.push(autoEvaluationRating)
        questionnaireRatings.push(questionnaireRating)
      }
      this.autoEvaluationRatings = autoEvaluationRatings
      this.questionnaireRatings = questionnaireRatings
    },

    loadingStarted() {
      this.isLoading = true
    },

    loadingDone() {
      this.isLoading = false
    },

    async showCommentsModal(peerComment: PeerComment) {
      this.currentEvaluatorComment = peerComment
      await this.$nextTick() // Allow modal to render before showing
      this.$bvModal.show(PEER_COMMENTS_MODAL_ID)
    },
  },
})

function getEvaluatorTableFields($t: Function) {
  return [
    {
      key: 'evaluator',
      label: $t('general_evaluator'),
      class: 'align-middle',
    },
    {
      key: 'role',
      label: $t('general_user_role'),
      class: 'align-middle',
    },
    {
      key: 'comment',
      label: $t('general_comments'),
      class: 'text-center align-middle',
    },
  ]
}
