






import Vue from 'vue'
import { AuthStoreKey } from '@/store/modules/auth-store'
import { Course } from '@/models/course'

type SelectOption = {
  value: Course | null
  text: string
}
export default Vue.extend({
  computed: {
    selected: {
      get(): Course | null {
        return this.$store.getters[AuthStoreKey.GETTER_CURRENT_COURSE]
      },
      async set(value: Course) {
        this.$store.dispatch(AuthStoreKey.ACTION_SET_CURRENT_COURSE, value).then(() => {
          location.reload()
        })
      },
    },
    courses(): Course[] {
      return this.$store.getters[AuthStoreKey.GETTER_COURSES]
    },
    options(): SelectOption[] {
      return this.courses.map((course) => {
        return {
          value: course,
          text: course.name,
        }
      })
    },
  },
})
