var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-form',{attrs:{"novalidate":"","data-cy":"questionnaireContainer"},on:{"change":_vm.onAnswerChange}},[_c('ValidationObserver',{ref:"veeObserver"},[_c('div',{staticClass:"container"},[_c('VLoadingOverlay',{attrs:{"loading":_vm.showLoadingIndicator,"full-page":true}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-2 pl-0"},[_c('b-card',{staticClass:"side-competence-card",attrs:{"id":"side-competence-card"}},[_c('b-card-title',[_c('div',{staticClass:"h2"},[_vm._v(_vm._s(_vm.competence.name))])]),_c('b-card-text',[_c('div',{domProps:{"innerHTML":_vm._s(_vm.competence.description_html)}})])],1)],1),_c('div',{staticClass:"col-xl-2"},_vm._l((_vm.sections),function(section){return _c('div',{key:section.id,staticClass:"mb-2"},[_c('h5',[_vm._v(_vm._s(section.title))]),_c('ul',{staticClass:"nav flex-column nav-pills"},_vm._l((section.questions),function(question){return _c('li',{key:question.data.id,staticClass:"nav-item"},[_c('span',{staticClass:"nav-link py-1",class:{ active: _vm.currentQuestion === question }},[_c('b-icon',{staticClass:"pr-1",attrs:{"icon":question.answer.isCompleted() ? 'check-circle' : 'circle'}}),_vm._v(" "+_vm._s(question.title)+" ")],1)])}),0)])}),0),_c('div',{staticClass:"col-xl-8"},[_c('div',[(_vm.currentQuestion)?_c('question',{attrs:{"question":_vm.currentQuestion,"current-user":_vm.user,"available-preambles":_vm.questionPreambles}}):_vm._e(),(_vm.answerInputTypeCode === 'text')?_c('div',{attrs:{"data-cy":"textAnswerSection"}},[_c('ValidationProvider',{attrs:{"rules":"required|max:10000"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('b-form-textarea',{class:classes,attrs:{"placeholder":_vm.$t('questionnaire_question_text_placeholder'),"rows":"8","no-resize":""},on:{"input":_vm.onAnswerChange},model:{value:(_vm.currentAnswer.data.value_text),callback:function ($$v) {_vm.$set(_vm.currentAnswer.data, "value_text", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"currentAnswer.data.value_text"}}),(_vm.showCharLimitIndicator)?_c('b-form-text',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.$t('questionnaire_question_text_char_count_indicator_label', { count: _vm.count, max: _vm.maxLength, }))+" ")]):_vm._e(),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])]}}],null,false,1670824671)})],1):(_vm.answerInputTypeCode === 'radio')?_c('div',{attrs:{"data-cy":"radioAnswerSection"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(radioValidator){return [_c('QuestionnaireRadioGroup',{attrs:{"available-values":_vm.currentAnswer.availableChoices,"validator":radioValidator},on:{"input":_vm.onAnswerChange},model:{value:(_vm.currentAnswer.data.available_choice_id),callback:function ($$v) {_vm.$set(_vm.currentAnswer.data, "available_choice_id", $$v)},expression:"currentAnswer.data.available_choice_id"}})]}}])})],1):(_vm.answerInputTypeCode === 'score')?_c('div',{attrs:{"data-cy":"scoreAnswerSection"}},[_c('div',{staticClass:"bg-light d-flex"},[_c('ValidationProvider',{staticClass:"mr-3",attrs:{"rules":"required|between:0,100","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
var classes = ref.classes;
return [_c('SliderCircle',{class:classes,attrs:{"text":_vm.currentAnswerValueFormattedAsInteger,"progress-width":15,"circle-width":15,"side":125,"data-cy":"circleSlider"},on:{"input":_vm.onAnswerChange},model:{value:(_vm.currentAnswer.data.value_decimal),callback:function ($$v) {_vm.$set(_vm.currentAnswer.data, "value_decimal", $$v)},expression:"currentAnswer.data.value_decimal"}}),_c('b-form-invalid-feedback',{attrs:{"state":valid}},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('ValidationProvider',{staticClass:"flex-grow-1",attrs:{"rules":_vm.currentQuestion.getCommentRules(),"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('b-form-textarea',{class:classes,attrs:{"disabled":_vm.currentAnswer.data.value_decimal === undefined,"placeholder":_vm.$t('questionnaire_comment_placeholder'),"no-resize":"","rows":"8","data-cy":"commentInput"},on:{"input":_vm.onAnswerChange},model:{value:(_vm.currentAnswer.data.comment),callback:function ($$v) {_vm.$set(_vm.currentAnswer.data, "comment", $$v)},expression:"currentAnswer.data.comment"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])]}}])})],1)]):_c('div',[_vm._v("Unexpected input type: "+_vm._s(_vm.answerInputTypeCode))])],1),_c('div',{staticClass:"mt-3"},[_c('div',{staticClass:"text-right"},[_c('b-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentQuestionIndex > 0),expression:"currentQuestionIndex > 0"}],attrs:{"variant":"outline-primary","data-cy":"btnPrevious"},on:{"click":function($event){return _vm.selectPreviousQuestion()}}},[_vm._v(_vm._s(_vm.$t('general_prev_action')))]),_c('b-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentQuestionIndex < _vm.allQuestions.length - 1),expression:"currentQuestionIndex < allQuestions.length - 1"}],staticClass:"ml-2",attrs:{"variant":"primary","data-cy":"btnNext"},on:{"click":function($event){return _vm.selectNextQuestion()}}},[_vm._v(_vm._s(_vm.$t('general_next_action')))]),_c('b-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentQuestionIndex === _vm.allQuestions.length - 1),expression:"currentQuestionIndex === allQuestions.length - 1"}],staticClass:"ml-2",attrs:{"variant":"primary","data-cy":"btnFinish"},on:{"click":function($event){return _vm.finish()}}},[_vm._v(_vm._s(_vm.$t('general_finish_action')))])],1)])])])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }