import { BigDecimal } from '@/utils/number-utils'

export class EvaluationStat {
  public readonly competenceId: number
  public readonly evaluationId: number
  public readonly questionnaireAvg?: BigDecimal

  constructor(competenceId: number, evaluationId: number, questionnaireAvg?: BigDecimal) {
    this.competenceId = competenceId
    this.evaluationId = evaluationId
    this.questionnaireAvg = questionnaireAvg
  }
}
