import { BigDecimal } from '@/utils/number-utils'

export class CourseStat {
  public readonly competenceId: number
  public readonly questionnaireAvg?: BigDecimal
  public readonly autoEvaluationAvg?: BigDecimal

  constructor(competenceId: number, questionnaireAvg?: BigDecimal, autoEvaluationAvg?: BigDecimal) {
    this.competenceId = competenceId
    this.questionnaireAvg = questionnaireAvg
    this.autoEvaluationAvg = autoEvaluationAvg
  }
}
