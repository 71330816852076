import { CourseStat } from './course-stat'
import { Competence } from './competence'
import { CourseUserStat } from './course-user-stat'
import { EvaluationUserStat } from './evaluation-user-stat'
import { Evaluation } from './evaluation'
import { EvaluationStat } from './evaluation-stat'

export class Grades {
  public readonly courseStats: CourseStat[] = []
  public readonly courseUserStats: CourseUserStat[] = []
  public readonly evaluationUserStats: EvaluationUserStat[] = []
  public readonly evaluationStats: EvaluationStat[] = []
  public readonly competencesById: Map<number, Competence> = new Map()
  public readonly evaluationsById: Map<number, Evaluation> = new Map()

  constructor(
    courseStats: CourseStat[],
    courseUserStats: CourseUserStat[],
    evaluationUserStats: EvaluationUserStat[],
    evaluationStats: EvaluationStat[],
    competencesById: Map<number, Competence>,
    evaluationsById: Map<number, Evaluation>
  ) {
    this.courseStats = courseStats
    this.courseUserStats = courseUserStats
    this.evaluationUserStats = evaluationUserStats
    this.evaluationStats = evaluationStats
    this.competencesById = competencesById
    this.evaluationsById = evaluationsById
  }

  public getCompetences(): Competence[] {
    return [...this.competencesById.values()]
  }

  public getEvaluations(): Evaluation[] {
    return [...this.evaluationsById.values()]
  }

  public getEvaluationsWithQuestions(): Evaluation[] {
    const evaluationsWithQuestionnaireAvg = []
    for (const curEval of this.getEvaluations()) {
      const data = this.evaluationStats.find(
        (x) => x.evaluationId === curEval.id && x.questionnaireAvg
      )
      if (data) {
        evaluationsWithQuestionnaireAvg.push(curEval)
      }
    }
    return evaluationsWithQuestionnaireAvg
  }

  public getCourseStatsForClass(competenceId: number): CourseStat | undefined {
    return this.courseStats.find((x) => x.competenceId === competenceId)
  }

  public getCourseStatsForUser(competenceId: number): CourseUserStat | undefined {
    return this.courseUserStats.find((x) => x.competenceId === competenceId)
  }

  public getEvaluationStatsForClass(
    evaluationId: number,
    competenceId: number
  ): EvaluationStat | undefined {
    return this.evaluationStats.find(
      (x) => x.evaluationId === evaluationId && x.competenceId === competenceId
    )
  }

  public getEvaluationStatsForUser(
    evaluationId: number,
    competenceId: number
  ): EvaluationUserStat | undefined {
    return this.evaluationUserStats.find(
      (x) => x.evaluationId === evaluationId && x.competenceId === competenceId
    )
  }
}
