
















import Vue from 'vue'
import { RouteName } from '@/router/route-name'

export default Vue.extend({
  computed: {
    baseTabs(): any[] {
      return [
        {
          icon: 'house',
          name: this.$t('dashboard'),
          route: 'dashboard',
        },
        {
          icon: 'bar-chart',
          name: this.$t('evaluations_page_title'),
          route: RouteName.EVALUATION_LIST,
        },
        {
          icon: 'info-circle',
          name: this.$t('competences_section_title'),
          route: 'competences-info',
        },
      ]
    },
  },
})
