

























import Vue, { PropType } from 'vue'

type CompetenceType = {
  id: number
  name: string
  logo_url: string
}

export default Vue.extend({
  props: {
    competences: {
      type: Array as PropType<CompetenceType[]>,
      required: true,
    },
    isCompetenceComplete: {
      type: Function as PropType<(competence: CompetenceType) => boolean>,
      required: true,
    },
    onCompetenceSelected: {
      type: Function as PropType<(competence: CompetenceType) => void>,
      required: true,
    },
  },
  data() {
    return {
      publicPath: process.env.BASE_URL as string,
    }
  },
})
