import Vue from 'vue'
import store from '@/store'
import { LoadingStoreKey } from '@/store/modules/loading-store'
import { SET_LAYOUT } from '@/store/modules/layout-store'
import Meta from 'vue-meta'
import { routeConfigurations } from './routes'
import VueRouter, { Route } from 'vue-router'
import { Position } from 'vue-router/types/router'
import { AuthStoreKey } from '@/store/modules/auth-store'
import { LayoutName } from '@/layouts/layout-name'
import { middlewareRegistry, MiddlewareName } from '@/middleware/middleware-registry'
import { MiddlewareOutcome } from '@/middleware/types'

Vue.use(Meta)
Vue.use(VueRouter)

const globalMiddlewares = [MiddlewareName.CHECK_SESSION_ABOUT_TO_EXPIRE]

const DEFAULT_LAYOUT = LayoutName.NavContent

const router = createRouter()
export default router

function createRouter() {
  const router = new VueRouter({
    scrollBehavior,
    mode: 'history',
    base: process.env.BASE_URL as string,
    routes: routeConfigurations,
    linkActiveClass: 'active',
    linkExactActiveClass: 'exact-active',
  })

  router.beforeEach(async (to, from, next) => {
    store.commit(LoadingStoreKey.SET_ROUTER_LOADING, true)

    let nextArgs
    try {
      await initGlobalStoreData()

      const middlewareNames = getMiddlewareNames(to)
      const outcome = await callMiddlewares(middlewareNames, to, from)
      store.commit(SET_LAYOUT, getLayout(to))
      nextArgs = outcome.argsForNextFn
    } catch ($ex) {
      nextArgs = $ex
    }
    store.commit(LoadingStoreKey.SET_ROUTER_LOADING, false)
    next(nextArgs)
  })

  router.afterEach(async (_to: Route, _from: Route) => {
    store.commit(LoadingStoreKey.SET_ROUTER_LOADING, false)
  })

  router.onError((error) => {
    const h = router.app.$createElement
    const vNodesMsg = [
      h('p', router.app.$tc('error_toast_instructions')),
      h('p', error.name),
      h('p', error.message),
    ]

    router.app.$bvToast.toast(vNodesMsg, {
      title: router.app.$tc('error'),
      variant: 'danger',
      noAutoHide: true,
    })
    throw error
  })

  return router
}

async function initGlobalStoreData() {
  if (store.getters[AuthStoreKey.GETTER_IS_LOGGED_IN]) {
    await store.dispatch(AuthStoreKey.GET_OR_INIT_USER)
  }
}

async function callMiddlewares(
  middlewareNames: MiddlewareName[],
  to: Route,
  from: Route
): Promise<MiddlewareOutcome> {
  for (const middlewareName of middlewareNames) {
    const middleware = middlewareRegistry.getByName(middlewareName)
    const outcome = await middleware.execute(to, from)
    if (outcome.stop) {
      return outcome
    }
  }
  return MiddlewareOutcome.continue()
}

function getMiddlewareNames(to: Route) {
  const names = [...globalMiddlewares]
  for (const record of to.matched) {
    const middlewares = record?.meta?.middlewares || []
    names.push(...middlewares)
  }
  return names
}

function getLayout(route: Route) {
  const match = route.matched.find((record) => record?.meta?.layout)
  return match ? match.meta.layout : DEFAULT_LAYOUT
}

function scrollBehavior(to: Route, _from: Route, savedPosition: Position | void) {
  if (savedPosition) {
    return savedPosition
  }
  if (to.hash) {
    return { selector: to.hash }
  }
  return { x: 0, y: 0 }
}
