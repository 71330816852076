import { RouteConfig } from 'vue-router'

import loginPage from '@/pages/Login.vue'
import dashboardPage from '@/pages/Dashboard.vue'
import competenceInfoPage from '@/pages/CompetencesInfo.vue'
import passwordRequestPage from '@/pages/PasswordResetRequest.vue'
import passwordResetPage from '@/pages/PasswordReset.vue'
import evaluationListPage from '@/pages/evaluation/EvaluationList.vue'
import evaluationDetailPage from '@/pages/evaluation/EvaluationDetail.vue'
import inscriptionListPage from '@/pages/InscriptionList.vue'
import questionnairePage from '@/pages/questionnaire/QuestionnaireIndex.vue'
import settingsIndexPage from '@/pages/SettingsIndex.vue'
import settingsProfilePage from '@/pages/SettingsProfile.vue'
import settingsPasswordPage from '@/pages/SettingsPassword.vue'
import userListPage from '@/pages/UserList.vue'
import userDetailPage from '@/pages/UserDetail.vue'
import competenceListPage from '@/pages/competence/CompetenceList.vue'
import competenceDetailPage from '@/pages/competence/CompetenceDetail.vue'
import adminEvaluationListPage from '@/pages/AdminEvaluationList.vue'
import adminEvaluationDetailPage from '@/pages/AdminEvaluationDetail.vue'
import adminVariousPage from '@/pages/admin-various/AdminVarious.vue'
import diagnosticsPage from '@/pages/Diagnostics.vue'
import error404Page from '@/pages/errors/404.vue'
import { LayoutName } from '@/layouts/layout-name'
import { RouteName } from './route-name'
import { AuthStoreKey } from '@/store/modules/auth-store'
import store from '@/store'
import { MiddlewareName } from '@/middleware/middleware-registry'

export const routeConfigurations: RouteConfig[] = [
  { path: '/', name: 'index', redirect: { name: RouteName.HOME } },

  {
    path: '/login',
    name: 'login',
    component: loginPage,
    meta: { middlewares: [MiddlewareName.GUEST_ONLY] },
  },
  {
    path: '/password/reset',
    name: 'password.request',
    component: passwordRequestPage,
    meta: { middlewares: [] },
    props: (route) => ({
      initialEmail: route.query.email,
    }),
  },
  {
    path: '/password/reset/:token',
    name: 'password.reset',
    component: passwordResetPage,
    meta: { middlewares: [] },
  },
  { path: '/home', name: RouteName.HOME, redirect: { name: 'dashboard' } },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: dashboardPage,
    meta: {
      layout: LayoutName.NavSidebarContent,
      middlewares: [MiddlewareName.AUTH],
    },
  },
  {
    path: '/evaluations',
    name: RouteName.EVALUATION_LIST,
    component: evaluationListPage,
    meta: {
      titleTransKey: 'evaluations_page_title',
      layout: LayoutName.NavSidebarContent,
      middlewares: [MiddlewareName.AUTH],
    },
  },
  {
    path: '/evaluations/:evaluationId/detail',
    name: RouteName.EVALUATION_DETAIL,
    component: evaluationDetailPage,
    meta: {
      titleTransKey: 'evaluation_detail_page_title',
      layout: LayoutName.NavSidebarContent,
      middlewares: [MiddlewareName.AUTH],
    },
    props: (route) => ({
      userId: store.getters[AuthStoreKey.GETTER_USER].id,
      evaluationId: Number.parseInt(route.params.evaluationId),
    }),
  },
  {
    path: '/inscriptions',
    component: inscriptionListPage,
    name: 'inscriptions',
    meta: {
      layout: LayoutName.NavSidebarContent,
      middlewares: [MiddlewareName.AUTH, MiddlewareName.ADMIN],
    },
  },
  {
    path: '/questionnaire/:evaluationId',
    component: questionnairePage,
    name: 'questionnaire',
    meta: { middlewares: [MiddlewareName.AUTH] },
    props: (route) => ({
      evaluationId: Number.parseInt(route.params.evaluationId),
    }),
  },
  {
    path: '/competences-info',
    name: 'competences-info',
    component: competenceInfoPage,
    meta: {
      layout: LayoutName.NavSidebarContent,
      middlewares: [MiddlewareName.AUTH],
    },
  },
  {
    path: '/users',
    name: RouteName.USER_LIST,
    component: userListPage,
    meta: {
      layout: LayoutName.NavSidebarContent,
      middlewares: [MiddlewareName.AUTH, MiddlewareName.ADMIN],
    },
  },
  {
    path: '/users/:userId',
    name: RouteName.USER_DETAIL,
    component: userDetailPage,
    meta: {
      layout: LayoutName.NavSidebarContent,
      middlewares: [MiddlewareName.AUTH, MiddlewareName.ADMIN],
    },
    props: (route) => ({
      userId: Number.parseInt(route.params.userId),
    }),
  },
  {
    path: '/competences',
    name: RouteName.COMPETENCE_LIST,
    component: competenceListPage,
    meta: {
      layout: LayoutName.NavSidebarContent,
      middlewares: [MiddlewareName.AUTH, MiddlewareName.ADMIN],
    },
  },
  {
    path: '/competences/:competenceIdOrNew',
    name: RouteName.COMPETENCE_DETAIL,
    component: competenceDetailPage,
    meta: {
      layout: LayoutName.NavSidebarContent,
      middlewares: [MiddlewareName.AUTH, MiddlewareName.ADMIN],
    },
    props: (route) => {
      const rawParam = route.params.competenceIdOrNew
      return {
        initialCompetenceId: rawParam === 'new' ? null : Number.parseInt(rawParam),
      }
    },
  },
  {
    path: '/admin-evaluations',
    name: RouteName.ADMIN_EVALUATION_LIST,
    component: adminEvaluationListPage,
    meta: {
      layout: LayoutName.NavSidebarContent,
      middlewares: [MiddlewareName.AUTH, MiddlewareName.ADMIN],
    },
  },
  {
    path: '/admin-evaluations/:evaluationId/detail',
    name: RouteName.ADMIN_EVALUATION_DETAIL,
    component: adminEvaluationDetailPage,
    meta: {
      layout: LayoutName.NavSidebarContent,
      middlewares: [MiddlewareName.AUTH, MiddlewareName.ADMIN],
    },
    props: (route) => ({
      evaluationId: Number.parseInt(route.params.evaluationId),
    }),
  },
  {
    path: '/admin-various',
    name: RouteName.ADMIN_VARIOUS,
    component: adminVariousPage,
    meta: {
      layout: LayoutName.NavSidebarContent,
      middlewares: [MiddlewareName.AUTH, MiddlewareName.ADMIN],
    },
  },
  {
    path: '/settings',
    component: settingsIndexPage,
    meta: { middlewares: [MiddlewareName.AUTH] },
    children: [
      { path: '', redirect: { name: 'settings.profile' } },
      { path: 'profile', name: 'settings.profile', component: settingsProfilePage },
      { path: 'password', name: 'settings.password', component: settingsPasswordPage },
    ],
  },
  {
    path: '/diagnostics',
    name: 'diagnostics',
    component: diagnosticsPage,
  },
  { path: '*', component: error404Page },
]
