import { Middleware } from '@/middleware/types'
import { AuthMiddleware } from '@/middleware/auth'
import { CheckSessionAboutToExpireMiddleware } from '@/middleware/check-session-about-to-expire'
import { GuestOnlyMiddleware } from '@/middleware/guest-only'
import { AdminMiddleware } from '@/middleware/admin'
import { Assert } from '@/utils/assert'

export enum MiddlewareName {
  ADMIN = 'admin',
  AUTH = 'auth',
  CHECK_SESSION_ABOUT_TO_EXPIRE = 'check-session-about-to-expire',
  GUEST_ONLY = 'guest-only',
}

class MiddlewareRegistry {
  private readonly nameToInstance = new Map<MiddlewareName, Middleware>()

  constructor() {
    this.nameToInstance.set(MiddlewareName.AUTH, new AuthMiddleware())
    this.nameToInstance.set(
      MiddlewareName.CHECK_SESSION_ABOUT_TO_EXPIRE,
      new CheckSessionAboutToExpireMiddleware()
    )
    this.nameToInstance.set(MiddlewareName.GUEST_ONLY, new GuestOnlyMiddleware())
    this.nameToInstance.set(MiddlewareName.ADMIN, new AdminMiddleware())
  }

  public getByName(name: MiddlewareName): Middleware {
    return Assert.notNullOrUndefined(
      this.nameToInstance.get(name),
      `Undefined middleware [${name}]`
    )
  }
}
export const middlewareRegistry = new MiddlewareRegistry()
