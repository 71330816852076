import { Logger } from '@/modules/logger'
import { LocalStorageKey } from './keys'

export { LocalStorageKey } from './keys'

const ROOT_KEY = 'app'
const VERSION = '1.0'

export class LocalStorageService {
  public getItem(key: LocalStorageKey): string | undefined {
    const internalKey = this.getInternalKey(key)
    try {
      return localStorage.getItem(internalKey) ?? undefined
    } catch (e) {
      Logger.warn(`Unable to get entry ${internalKey}`, e)
    }
  }

  public setItem(key: LocalStorageKey, value: string) {
    const internalKey = this.getInternalKey(key)
    try {
      localStorage.setItem(internalKey, value)
    } catch (e) {
      Logger.warn(`Unable to set entry ${internalKey}`, e)
    }
  }

  public removeItem(key: LocalStorageKey) {
    const internalKey = this.getInternalKey(key)
    try {
      localStorage.removeItem(internalKey)
    } catch (e) {
      Logger.warn(`Unable to set entry ${internalKey}`, e)
    }
  }

  public clear() {
    for (const storageKey of Object.values(LocalStorageKey)) {
      this.removeItem(storageKey)
    }
  }

  private getMainKey() {
    return `${ROOT_KEY}/${VERSION}`
  }

  private getInternalKey(externalKey: LocalStorageKey) {
    return `${this.getMainKey()}/${externalKey}`
  }
}
