import { Competence } from '@/services/common/models/competence'
import { UserRole } from '../user/user-roles'

export type BooleanAsInt = 0 | 1

export enum EvaluationStatusCode {
  NOT_STARTED = 'not_started',
  OPEN_FOR_SUBMISSIONS = 'open_for_submissions',
  DONE = 'done',
}

export type Evaluation = {
  id: number
  start_datetime: string
  end_datetime: string
  grades_are_ready: boolean
  status_code: EvaluationStatusCode
  question_count: number
  answered_count: number
  competences: Competence[]
} & EvaluationEditable

export type EvaluationEditable = {
  name: string
  is_published: BooleanAsInt
}

export type Respondent = {
  id: number
  name: string | null
  role: UserRole
}

export type PeerCommentType = {
  questionId: number
  competenceId: number
  competenceCode: string
  competenceName: string
  respondentId: number
  comment: string
}

export type EvaluationDetail = {
  evaluation: {
    id: number
    name: string
    start_datetime: string
    end_datetime: string
    is_published: BooleanAsInt
    grades_are_ready: BooleanAsInt
    evaluation_status: EvaluationStatus
  }
  respondents: Respondent[]
  peerComments: PeerCommentType[]
}

export type EvaluationStatus = {
  id: number
  code: EvaluationStatusCode
}
